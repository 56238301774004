import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { AppState } from '../app.state';
import { initialVesselsOverviewState } from "../../service-provider-space/danish-shipping/store/reducers/vessels-overview.reducers";
import { initialScenariosState } from '../../service-provider-space/danish-shipping/store/reducers/scenarios.reducers';


export function sessionStorageMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      try {
        const restoredState: Partial<AppState> = {
          user: JSON.parse(sessionStorage.getItem('user') || 'null'),
          vesselsOverview: JSON.parse(sessionStorage.getItem('vesselsOverview') || 'null'),
          scenarios: JSON.parse(sessionStorage.getItem('scenarios') || 'null'),
        };

        if (restoredState.vesselsOverview==null) {
          restoredState.vesselsOverview = {
            ...initialVesselsOverviewState,
          };
        }
        if (restoredState.vesselsOverview) {
          restoredState.vesselsOverview = {
            ...initialVesselsOverviewState,
            ...restoredState.vesselsOverview,
          };
        }

        if (restoredState.scenarios) {
          restoredState.scenarios = {
            ...initialScenariosState,
            ...restoredState.scenarios,
          };
        }

        state = { ...state, ...restoredState };
      } catch (error) {
        console.error('Error restoring state from sessionStorage:', error);
      }
    }

    const nextState = reducer(state, action);

    try {
      if (nextState.user) {
        sessionStorage.setItem('user', JSON.stringify(nextState.user));
      }
      if (nextState.vesselsOverview) {
        sessionStorage.setItem('vesselsOverview', JSON.stringify(nextState.vesselsOverview));
      }
      if (nextState.scenarios) {
        sessionStorage.setItem('scenarios', JSON.stringify(nextState.scenarios));
      }
    } catch (error) {
      console.error('Error saving state to sessionStorage:', error);
    }

    return nextState;
  };
}