import { createAction, props } from '@ngrx/store';

export const loadCompanyId = createAction("[App init] load companyId",props<{id:any}>());
export const registerCurrentUser = createAction("[App init] register current User",props<{user:any}>());
export const registerUserInfo = createAction("[Side menu init] register user info",props<{user:any}>());

export const setUser = createAction('[User] Set User', props<{ user: any }>());
export const clearUser = createAction('[User] Clear User');
export const UserActions={
    loadCompanyId,
    registerCurrentUser,
    registerUserInfo,
    setUser,
    clearUser
}