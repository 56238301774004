<div class="cards-section">
    <!-- Content for Tab 1 -->
  <div class="card">
    <div class="card-title">
      <label class="main-title">Vessel Type</label>

        <div class="card-value-block">
            <img src="../../../assets/danish-shipping-assets/ship-dark-icon.svg" alt="" class="">
            <label for="" class="card-value">{{step1data?.vesselType}}</label>
        </div>
    </div>
  
    <p class="card-description">
      The vessel type is such as container ship, tanker, or bulk carrier, which determines the specific parameters and assumptions used in the lifecycle screening.
    </p>
  </div>
  <div class="card">
    <div class="card-title">
      <label class="main-title">DWT</label>

        <div class="card-value-block">
            <img src="../../../assets/danish-shipping-assets/file-dark-icon.svg" alt="" class="">
            <label for="" class="card-value">{{step1data?.vesselDWT}} tonnes</label>
        </div>
    </div>
  
    <p class="card-description">
        The vessel's Deadweight Tonnage (DWT) is the total weight capacity used as the basis for lifecycle screening calculations and assumptions.
    </p>
  </div>
  <!-- <div class="card">
    <div class="card-title">
      <label class="main-title">Built location</label>

        <div class="card-value-block">
            <img src="../../../assets/danish-shipping-assets/location-dark-icon.svg" alt="" class="">
            <label for="" class="card-value">Ukraine</label>
        </div>
    </div>
  
    <p class="card-description">
        The geographical location of the shipyard where the vessel is constructed influences the climate impact factors in the lifecycle screening.
    </p>
  </div> -->
  <div class="card">
    <div class="card-title">
      <label class="main-title">Length</label>

        <div class="card-value-block">
            <img src="../../../assets/danish-shipping-assets/ruler-dark-icon.svg" alt="" class="">
            <label for="" class="card-value">{{step1data?.vesselLength}} M</label>
        </div>
    </div>
  
    <p class="card-description">
        The size information of the vessel is including Length (L) and Beam (B), which are fundamental for defining the vessel’s structural properties in the lifecycle screening.
    </p>
  </div>
  <div class="card">
    <div class="card-title">
      <label class="main-title">Beam</label>

        <div class="card-value-block">
            <img src="../../../assets/danish-shipping-assets/ruler-dark-icon.svg" alt="" class="">
            <label for="" class="card-value">{{step1data?.vesselBeam}} M</label>
        </div>
    </div>
  
    <p class="card-description">
        The size information of the vessel is including Length (L) and Beam (B), which are fundamental for defining the vessel’s structural properties in the lifecycle screening.
    </p>
  </div>


</div>