import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuelOption } from 'src/app/models/fuel-option.model';
import { VesselCalculationInventoryService } from 'src/app/services/danish-shipping-services/vessel-calculation-inventory.service';

@Component({
  selector: 'app-add-fuel-option',
  templateUrl: './add-fuel-option.component.html',
  styleUrls: ['./add-fuel-option.component.css']
})
export class AddFuelOptionComponent implements OnInit {
@Output() toogleModal = new EventEmitter();
@Input() public showForm: boolean;
public fuelOptionToCreate: FormGroup;

 public submitted = false;
  constructor(
    private fb: FormBuilder,
    private fuelService :VesselCalculationInventoryService 
              
  ) { }

  ngOnInit(): void {
    this.fuelOptionToCreate = this.fb.group({
      title: ["", Validators.required],
      coefficient: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      percentage: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scenarioCategoryId: ["", Validators.required],
    });
  }
  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.fuelOptionToCreate.valid) {
      const fuelOption: FuelOption = this.fuelOptionToCreate.value;

      this.fuelService.addFuelOption(fuelOption).subscribe(
        (response) => {
          console.log('Fuel Option Added Successfully', response);
          window.location.reload();
        },
        (error) => {
          console.error('Error adding fuel option', error);
        }
      );
    }}
}
