import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VesselLifeCycleService {


  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public initiateVesselLifeCycle(id: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessel-life-cycle/initiate/${id}`,
      this.helperService.headers()
    );
  }

  public getLifeCycleBaselineScenario(companyVesselId: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-life-cycle/get-lifecycle-baseline/${companyVesselId}`,
      this.helperService.headers()
    );
  }

  public getVesselLifecycleScenarios(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-life-cycle/vessel-lifecycle-scenarios/${id}`,
      this.helperService.headers()
    );
  }

  public getVesselDataById(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-life-cycle/vessel/${id}`,
      this.helperService.headers()
    );
  }



}
