import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuelOption } from 'src/app/models/fuel-option.model';
import { VesselCalculationInventoryService } from 'src/app/services/danish-shipping-services/vessel-calculation-inventory.service';

@Component({
  selector: 'app-edit-fuel-option',
  templateUrl: './edit-fuel-option.component.html',
  styleUrls: ['./edit-fuel-option.component.css']
})
export class EditFuelOptionComponent implements OnInit {
  @Output() toogleModal = new EventEmitter();
  @Input() public showForm: boolean = false;
  @Input() public fuelOption: FuelOption;
  public fuelOptionToEdit: FormGroup;
  public submitted = false;
  constructor(
    private fb: FormBuilder,
    private fuelService: VesselCalculationInventoryService
  ) {
   }

   ngOnInit(): void {
    this.fuelOptionToEdit = this.fb.group({
      id: [this.fuelOption.id],
      title: [this.fuelOption.title, Validators.required],
      coefficient: [
        this.fuelOption.coefficient,
        [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')],
      ],
      percentage: [
        this.fuelOption.percentage,
        [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]+)?$')],
      ],
      scenarioCategoryId: [this.fuelOption.scenarioCategoryId, Validators.required],
    });
  }
  public hideModal(): void {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.fuelOptionToEdit.valid) {
      const updatedFuelOption: FuelOption = this.fuelOptionToEdit.value;

      this.fuelService.updateFuelOption(updatedFuelOption).subscribe(
        (response) => {
          console.log('Fuel Option Updated Successfully', response);
          this.hideModal();
        },
        (error) => {
          console.log('Error updating fuel option', error);
        }
      );
    }
  }

}
