import { AppState } from "@auth0/auth0-angular";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ScenariosState } from "../reducers/scenarios.reducers";

export const selectScenariosState = createFeatureSelector<AppState,ScenariosState>('scenarios');

export const selectBaseScenarioData = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.baseScenarioData
);

export const selectShowMaterialModal = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.showMaterialModal
);

export const selectMaterialOptions = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.materialOptions
);

export const selectBaselineMetaData = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.baselineMetaData
);

export const selectScenarioInventories = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.scenarioInventories
);

export const selectScenarioInventories_children = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.scenarioInventories_children
);

export const selectCurrentStep = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.currentStep
);

export const selectVesselData = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.vesselData
);

export const selectScenariosData = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.scenariosData
);

export const selectResultScopes = createSelector(
    selectScenariosState,
    (state: ScenariosState) => state.resultScopes
);