<div class="modals">
    <div class="header">
        <em class="icon-close"  (click)="cancel()"></em>
    </div>
    <div class="body" *ngIf="isAdmin==true">
        <div class="title-container">
            <img src="../../../../assets/flowcard.png" alt="" class="title-image">
            <label for="" class="title">REFILL CREDITS</label>
        </div>
        <p class="title-description">
            Improve your calculations by adding more calculation credits.
        </p>
        <div class="offers">
            <label for="" class="section-title mt-4">Select the amount of FlowCredits:</label>
            <div class="offers-container">
                <div (click)="selectedOffer=1" [ngClass]="{'selected': selectedOffer==1,'offer-card':selectedOffer!=1}">
                    <p class="amount">+5</p>
                    <p class="label">Flowcredits</p>
                    <div class="percent">
                        <div class="burst">
                            <span>%</span>
                        </div>
                        <p class="percent-value">0%</p>
                    </div>
                </div>
                <div  (click)="selectedOffer=2" [ngClass]="{'selected': selectedOffer==2,'offer-card':selectedOffer!=2}">
                    <p class="amount">+10</p>
                    <p class="label">Flowcredits</p>
                    <div class="percent">
                        <div class="burst">
                            <span>%</span>
                        </div>
                        <p class="percent-value">10%</p>
                    </div>
                </div>
                <div (click)="selectedOffer=3" [ngClass]="{'selected': selectedOffer==3,'offer-card':selectedOffer!=3}">
                    <p class="amount">+20</p>
                    <p class="label">Flowcredits</p>
                    <div class="percent">
                        <div class="burst">
                            <span>%</span>
                        </div>
                        <p class="percent-value">25%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="operations">
            <label for="" class="section-title">Select the payment method :</label>
            <div class="operations-container">
                <div class="operation">
                    <p class="operation-label">Card payment:</p>
                    <p class="operation-label  d-flex align-items-center">
                        Checkout
                        <em class="icon-back_arrow ml-3"></em>
                    </p>
                </div>
                <div class="operation mt-2">
                    <p class="operation-label">Card payment:</p>
                    <p class="operation-label  d-flex align-items-center">
                        Checkout
                        <em class="icon-back_arrow ml-3"></em>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="not-admin" *ngIf="isAdmin==false">
        <i class="fa fa-user"></i>
        <label for="" class="title">Contact you Administrator</label>
        <p class="desc">Please contact your company administrator for refill of credits.</p>
        <p class="contact">If any questions contact us <a class="here">here</a>.</p>
    </div>

</div>

<div class="overlay "></div>

<!--
    <em class="icon-close" (click)="cancel()"></em>
    <div class="main-modal-content">
        <div class="row">
            <div class="col-12  centered">
                <label class="modal-title">REFILL CREDITS</label>

            </div>
            <p class="modal-header">
                Improve your calculations by adding more calculation credits.
            </p>
            <div class="refill-container">
                <div class="custom-col" (click)="selectCredit('+5')" [ngClass]="{'selected-credit': selectedCredit == '+5' }">

                    <p class="text-value">+5</p>
                    <p class="text-price" id="text-price-5">{{price5}}</p>
                </div>
                <div class="custom-col" (click)="selectCredit('+10')" [ngClass]="{'selected-credit': selectedCredit == '+10' }">
                    <p class="discount-10">-10%</p>
                    <div class="icon-discount-10"><em class="icon-offer1"></em></div>

                    <p class="text-value">+10 </p>
                    <p class="text-price" id="text-price-10">{{price10}} </p>
                </div>
                <div class="custom-col" (click)="selectCredit('+25')" [ngClass]="{'selected-credit': selectedCredit == '+25' }">
                    <p class="discount-25">-25%</p>
                    <div class="icon-discount-25"><em class="icon-offer1"></em></div>

                    <p class="text-value">+25 </p>
                    <p class="text-price" id="text-price-25">{{price25}} </p>
                </div>
            </div>
            <div class="refill-container-message">
                <div class="custom-col-message">
                    <p class="text-message">{{submessage5}}</p>
                </div>
                <div class="custom-col-message">
                    <p class="text-message">{{submessage10}}</p>
                </div>
                <div class="custom-col-message">
                    <p class="text-message">{{submessage25}}</p>
                </div>
            </div>
            <div class="refill-container-currency">
                <div class="custom-col-currency">
                    <p class="currency-eur" (click)="selectCurrency('eur')" [ngClass]="{'selected-currency': selected == 'eur' }">EUR</p>
                    <p class="currency-dkk" (click)="selectCurrency('dkk')" [ngClass]="{'selected-currency': selected == 'dkk' }">DKK</p>
                    <p class="currency-usd" (click)="selectCurrency('usd')" [ngClass]="{'selected-currency': selected == 'usd' }">USD</p>
                </div>
            </div>
            <div class="total-container">
                <p class="total-value">Total {{selectedTotal}}</p>
                <a style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='nocharge' } "><button class="btn-checkout ">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit5-USD" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit5-USD' } "><button class="btn-checkout " (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit5-DKK" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit5-DKK' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit5-EUR" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit5-EUR' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit10-USD" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit10-USD' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit10-EUR " style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit10-EUR' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit10-DKK" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit10-DKK' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit25-USD" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit25-USD' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit25-EUR" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit25-EUR' } "><button class="btn-checkout "  (click)="checkout()">Checkout</button></a>
                <a href="javascript:void(0) " data-cb-type="checkout" data-cb-item-0="credit25-DKK" style="display: none;" [ngClass]="{ 'selected-charge': creditCharge=='credit25-DKK' } "><button class="btn-checkout " (click)="checkout()">Checkout</button></a>
            </div>
            <p class="more-info ">More than 25 credits please contact us<a class="contact-link ">here.</a></p>

        </div>
    </div>
-->