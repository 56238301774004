<div class="reflow-menu">
    <ul class="nav navbar-nav">
        <li class="active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <img src="{{ currentUser?.profilePicture }}" alt="" />
            <label class="sidebar-username" id="user">Hi {{ currentUser?.fullName }}</label
      >
      <em class="fa fa-angle-down expend-icon" id="expend"></em>
    </li>
    <!-- <li class="collapse" id="collapseExample" (click)="showResetModal(true)">
      <em class="fa fa-lock"></em>
      <label>Reset password</label>
        </li> -->
        <li class="collapse" id="collapseExample" (click)="showImageModal(true)">
            <em class="fa fa-picture-o"></em>
            <label>Change profile image</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="logOut()">
            <em class="fa fa-sign-out"></em>
            <label>Log out</label>
        </li>
        
    </ul>
    <ul class="nav navbar-nav flexed separated">
        <li routerLink="dashboard">
            <em class="icon-project" style="font-size: 18px;"></em>
            <label>Dashboard</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="companies">
            <em class="icon-company"></em>
            <label>Companies</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="external-companies">
            <em class="icon-ex-company"></em>
            <label>External companies</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="platform-updates">
            <em class="icon-basic"></em>
            <label>Platform Updates</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="mastersheet/databases">
            <em class="icon-file-earmark-spreadsheet"></em>
            <label>Edit MasterSheet</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="admin-academy">
            <em class="icon-new_academy"></em>
            <label>Reflow Academy</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="Validation-dashboard">
            <em class="icon-certificate" style="font-size: 22px;"></em>
            <label>Validation dashboard</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="quima-usage">
            <em class="icon-certificate" style="font-size: 22px;"></em>
            <label>Quima Usage</label>
        </li>
    </ul>

    <ul class="nav navbar-nav flexed separated">
        <li routerLink="/quantify">
            <em class="icon-new_quantify"></em>
            <label>Products </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="/share">
            <em class="icon-new_share active-menu" ></em>
            <label class="active-label" >Share </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="/compareProjects">
            <em class="icon-new_compare active-menu"></em>
            <label >Compare <small class="beta-tag"> Beta</small></label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="/company-assessment">
            <em class="icon-company active-menu"></em>
            <label >Company <small class="beta-tag"> Beta</small></label>
        </li>
    </ul>
   

    <ul class="nav navbar-nav flexed">
        <li routerLink="/reflow-academy">
            <em class="icon-new_academy active-menu" ></em>
            <label class="active-label">Academy</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="/danish-shipping">
            <em class="icon-new_academy active-menu" ></em>
            <label class="active-label">Vessels </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="/homeAdmin/fuelOption">
            <em class="icon-new_academy active-menu" ></em>
            <label class="active-label">Fuel Option </label>
        </li>
    </ul>
</div>
<app-reset-password (toogleModal)="showResetModal($event)" [userId]="currentUser.id" [showModal]="showResetView" *ngIf="showResetView">
</app-reset-password>
<app-profile-image (toogleModal)="showImageModal($event)" *ngIf="showImageView"></app-profile-image>