import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { UserState } from '../../../../store/reducers/user.reducers';
import { VesselsService } from '../../../../services/danish-shipping-services/vessels.service';
import { VesselsOverviewState } from '../reducers/vessels-overview.reducers';
import { VesselsOverviewActions } from '../actions/vessels-overview.actions';
import { selectCompanyId } from '../../../../store/selectors/user.selectors';
import { VesselLifeCycleService } from '../../../../services/danish-shipping-services/vessel-life-cycle.service';
import { VesselCalculationInventoryService } from '../../../../services/danish-shipping-services/vessel-calculation-inventory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScenariosActions } from '../actions/scenarios.actions';

@Injectable()
export class ScenariosEffects {
  constructor(
    private actions$: Actions,
    private vesselsService: VesselsService,
    private vesselLifeCycleService: VesselLifeCycleService,
    private vesselCalculationInventoryService: VesselCalculationInventoryService,
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private userStore: Store<{ user: UserState }>,
    private router: Router, 
    private route: ActivatedRoute,
  ) {}

  fetchBaselineBuildingScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.fetchBaselineBuildingScenario),
      mergeMap(action => {
        return this.vesselLifeCycleService.getLifeCycleBaselineScenario(action.id).pipe(
            map(baseScenarioData => {
                return ScenariosActions.fetchBaselineBuildingScenarioSuccess({ baseScenarioData });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.fetchBaselineBuildingScenarioFailed({error}));
            })
        );
      })
    )
  );

  fetchBaseScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.fetchBaseScenarios),
      mergeMap(action => {
        return this.vesselLifeCycleService.getVesselLifecycleScenarios(action.id).pipe(
            map(data => {
                return ScenariosActions.fetchBaseScenariosSuccess({ data });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.fetchBaseScenariosFailed({error}));
            })
        );
      })
    )
  );


  getLifecycleOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getLifecycleOptions),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getOptionDataForLifeCycleScenario(action.title).pipe(
            map(options => {
                return ScenariosActions.getLifecycleOptionsSuccess({ options });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getLifecycleOptionsFailed({error}));
            })
        );
      })
    )
  );

  getScenarioInventories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getScenarioInventories),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getOptionsByLifeCycleScenarioId(action.id).pipe(
            map(inventories => {
                const parent=inventories.filter(x=>x.parentId==0);
                const tempChild=inventories.filter(x=>x.parentId!=0);
                let child={};
                parent.forEach(element => {
                  child[element['id']]=tempChild.filter(x=>x.parentId==element['id'])[0];
                });
                return ScenariosActions.getScenarioInventoriesSuccess({ parent,child });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getScenarioInventoriesFailed({error}));
            })
        );
      })
    )
  );

  saveOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.selectOption),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.addScenarioInventory(action.option).pipe(
            map(option => {
              if(action.isAdding=='parent'){
                return ScenariosActions.selectOptionSuccess({ option:option.data });
              }
              return ScenariosActions.selectChildOptionSuccess({ option:option.data });

            }),
            catchError(({ error }) => {
              return of(ScenariosActions.selectOptionFailed({error}));
            })
        );
      })
    )
  );

  updateOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.updateOption),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.updateScenarioInventory(action.option).pipe(
            map(option => {
              if(action.isUpdating=='parent'){
                return ScenariosActions.updateOptionSuccess({ option:option.data });
              }
              return ScenariosActions.updateChildOptionSuccess({ option:option.data });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.updateOptionFailed({error}));
            })
        );
      })
    )
  );

  deleteOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.deleteOption),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.deleteScenarioInventory(action.id).pipe(
            map(option => {
                return ScenariosActions.deleteOptionSuccess({ id:action.id });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.deleteOptionFailed({error}));
            })
        );
      })
    )
  );

  confirmStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.confirmStep),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.confirmLifeCycleScenario(action.scenarioId).pipe(
            map(result => {
                return ScenariosActions.confirmStepSuccess({ result });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.confirmStepFailed({error}));
            })
        );
      })
    )
  );

  getVesselData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getVesselData),
      mergeMap(action => {
        return this.vesselLifeCycleService.getVesselDataById(action.vesselId).pipe(
            map(vesselData => {
                return ScenariosActions.getVesselDataSuccess({ vesselData });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getVesselDataFailed({error}));
            })
        );
      })
    )
  );

  getScenarioInventoriesForResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getScenarioInventoriesForResult),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getOptionsByLifeCycleScenarioId(action.id).pipe(
            map(data => {
               
                return ScenariosActions.getScenarioInventoriesForResultSuccess({ data,step:action.step });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getScenarioInventoriesFailed({error}));
            })
        );
      })
    )
  );

  getScope1Impact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getScope1Impact),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getScope1Impact(action.id).pipe(
            map(data => {
               
                return ScenariosActions.getScope1ImpactSuccess({ data });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getScope1ImpactFailed({error}));
            })
        );
      })
    )
  );
  getScope2Impact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getScope2Impact),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getScope2Impact(action.id).pipe(
            map(data => {
               
                return ScenariosActions.getScope2ImpactSuccess({ data });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getScope1ImpactFailed({error}));
            })
        );
      })
    )
  );

  getScope3Impact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenariosActions.getScope3Impact),
      mergeMap(action => {
        return this.vesselCalculationInventoryService.getScope3Impact(action.id,action.vesselType).pipe(
            map(data => {
               
                return ScenariosActions.getScope3ImpactSuccess({ data });
            }),
            catchError(({ error }) => {
              return of(ScenariosActions.getScope1ImpactFailed({error}));
            })
        );
      })
    )
  );
}