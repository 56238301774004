import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { select, Store } from '@ngrx/store';
import { FoldersActions } from '../../store/actions/folder.actions';
import { Observable } from 'rxjs';
import { selectArchiveFolderId, selectArchiveFolderName, selectArchiveModaalData, selectArchiveOperation, selectError, selectResult } from '../../store/selectors/folder.selectors';
import { filter, take } from 'rxjs/operators';
import { ProjectsActions } from '../../store/actions/project.actions';
import { ProjectsState } from '../../store/reducers/project.reducer';

@Component({
  selector: 'app-archive-manager-modal',
  templateUrl: './archive-manager-modal.component.html',
  styleUrls: ['./archive-manager-modal.component.scss']
})
export class ArchiveManagerModalComponent implements OnInit,OnChanges {
  @Output() result = new EventEmitter<{ success: boolean, record?: any, error?: any }>();
  archiveOperation:any='';
  archivingTarget:any='';
  data$:Observable<any>;
  result$:Observable<any>;
  error$:Observable<any>;
  source:any=null;
  loading:boolean=false;
  archiveData:any=null;
  lastfoldertDispatched:any=null;
  constructor(
    private foldersStore: Store<{folders:FoldersState}>,
    private projectsStore: Store<{ projects: ProjectsState }>,
  ) { 
    this.data$=foldersStore.pipe(select(selectArchiveModaalData));
    this.result$ = foldersStore.select(selectResult);
    this.error$ = foldersStore.select(selectError);
    this.projectsStore.pipe(
      select(state=>state.projects.lastProjectsDispatcher)
    ).subscribe(ans=>{
      
      this.lastfoldertDispatched=ans;
      switch (ans.folderName) {
        case 'Draft':
          this.lastfoldertDispatched="draftProjects";
          break;
        case 'Completed':
          this.lastfoldertDispatched="completedProjects";
          break;
        case 'Archived':
          this.lastfoldertDispatched="archivedProjects";
          break;
          default:
          this.lastfoldertDispatched="subfolderProjects";
          break;
      }
    })
  }

  ngOnInit(): void {
    this.data$.subscribe(ans=>{
      this.archiveData=ans
      this.source=ans.source;
    })
  }
  ngOnChanges(): void {
    
  }

  hideArchiveModal(){

  }

  public toggleUpsertFolder(){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:false,
          archiveOperation:null,
          folderName:null,
          folderId:null,
        }
      }
    ));
  }

  archiveFolder(id,operation){
    this.loading=true;
    if(this.archiveData.targetType=='folder'){
      if(operation=='archive'){
        this.foldersStore.dispatch(FoldersActions.archiveFolder({id}));
      }else{
        this.foldersStore.dispatch(FoldersActions.restoreFolder({id}));
      }
    }else{
      if(operation=='archive'){
        this.foldersStore.dispatch(FoldersActions.archiveProject({id:id,key:this.lastfoldertDispatched}));
      }else{
        this.foldersStore.dispatch(FoldersActions.restoreProject({id:id,key:this.lastfoldertDispatched}));
      }
    }
    
    this.checkForResults(id,operation);
  }
  

  checkForResults(id,operation){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      console.error('Error occurred:', error);
      this.loading=false;
      this.result.emit({ success: false, error: true });
    });
      this.result$.pipe(
        filter(result => !!result), 
        take(1)
      ).subscribe(result => {

        this.error$.pipe(
          filter(error => !!error), 
          take(1)
        ).subscribe(error => {
          return
        });

        if(this.archiveData.targetType=='folder'){
          let src=(this.source=='Draft'?'draftFolders':'completedFolders');
          if(operation=='archive'){
            this.foldersStore.dispatch(FoldersActions.spliceFolder({id:id,source:src,destination:'archivedFolders'}));
          }else{
            this.foldersStore.dispatch(FoldersActions.restoreSplicedFolder({id:id,source:'archivedFolders'}));
          }
        }else{
          
        }
        this.loading=false;
        this.result.emit({ success: true});
      });
    
  }

}
