import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompaniesService } from '../../../services/yardscore-services/companies.service';

@Component({
  selector: 'app-ysa-upsert-company',
  templateUrl: './ysa-upsert-company.component.html',
  styleUrls: ['./ysa-upsert-company.component.scss']
})
export class YSAUpsertCompanyComponent implements OnInit {

  @Input() actionType:any=null;
  @Input() currentCompany:any=null;
  @Output() closeUpsertForm:any= new EventEmitter();

  public companyTypes:any=[];
  public companyToUpsert: FormGroup;
  public companyType="Select company type";

  constructor(
    private fb: FormBuilder,
    private companiesService:CompaniesService
  ) { }

  ngOnInit(): void {
    this.fillCompanyTypes();
    this.resetForm()
    
  }

  public resetForm(){
    this.companyToUpsert = this.fb.group({
      id: [this.currentCompany?.id!=null?this.currentCompany?.id:0],
      companyName: [this.currentCompany?.companyName!=null?this.currentCompany?.companyName:""],
      companyType: [this.currentCompany?.companyType!=null?this.currentCompany?.companyType:""],
      firstAddress: [this.currentCompany?.firstAddress!=null?this.currentCompany?.firstAddress:""],
      secondAddress: [this.currentCompany?.secondAddress!=null?this.currentCompany?.secondAddress:""],
      country: [this.currentCompany?.country!=null?this.currentCompany?.country:""],
    });
    this.companyType=this.currentCompany?.companyType;
    this.companyToUpsert.patchValue({companyType: this.companyType,});
  }

  public fillCompanyTypes(){
    this.companyTypes=[
      {
        typeName:"Shipowner",
        subTypes:[],
        checked:false
      },{
        typeName:"Shipyard",
        subTypes:[
          {
            typeName:"Repair Yard",
            checked:false
          },{
            typeName:"Recycling Yard",
            checked:false
          }
        ],
        checked:false
      },{
        typeName:"Insurance Institution",
        subTypes:[],
        checked:false
      },{
        typeName:"Finance Institution",
        subTypes:[],
        checked:false
      },{
        typeName:"Legal Institution",
        subTypes:[],
        checked:false
      }
    ]
  }

  public toggleCheck(idx){
    if(this.companyTypes[idx].subTypes.length==0){
      if(this.companyType==this.companyTypes[idx].typeName){
        this.companyType="Select company type";
      }else{
        this.companyType=this.companyTypes[idx].typeName;
      }
    }
    
  }

  public toggleSubCheck(idx,subIdx){
    if(this.companyType==this.companyTypes[idx].subTypes[subIdx].typeName){
      this.companyType="Select company type";
    }else{
      this.companyType=this.companyTypes[idx].subTypes[subIdx].typeName;
    } 
    
  }

  public dismiss(){
    this.closeUpsertForm.emit(false);
  }

  public upsert(){
    this.companyToUpsert.patchValue({companyType: this.companyType,});
    if(this.actionType=="create"){
      this.companyToUpsert.patchValue({id: 0});
      this.companiesService.createCompany(this.companyToUpsert.value).subscribe(ans=>{
        this.closeUpsertForm.emit(true);
      },err=>{
      })
    }
    else{
      this.companiesService.createCompany(this.companyToUpsert.value).subscribe(ans=>{
        this.closeUpsertForm.emit(true);
      },err=>{
      })
    }
    
  }

}
