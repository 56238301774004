<div class="vessels-header-container">
    <div class="title-container">
        <h1 class="title-nav">What do you want to explore?</h1>
    </div>
    <div class="card-container">
        <div class="section-card-one">
            <h1 class="vesselt-title">Vessel Lifecycle Screening</h1>
            <p class="description-style">
                This tool provides a high-level assessment of the vessel's lifecycle emissions, known as Lifecycle
                Screening. Unlike a full Lifecycle Assessment (LCA), this screening offers a broad, preliminary overview
                of the climate impacts during construction, operation, and end-of-life phases. Lifecycle Screening helps
                identify key emission areas where improvements could be most effective. It serves as an initial step for
                more detailed assessments, guiding decision-makers in making informed decisions early in the planning or
                modification process. Use this tool to quickly evaluate the climate effects of different scenarios, such
                as changes in fuel type, technology upgrades, or operational adjustments, before committing to more
                in-depth analyses. This approach supports strategic decision-making in vessel lifecycle management. Show
                less
            </p>
            <button class="start-button-style" (click)="startScreening()">Start Screening<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M4.16602 9.99984H15.8327M15.8327 9.99984L9.99935 4.1665M15.8327 9.99984L9.99935 15.8332" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></button>
        </div>
        <div class="section-card-two" style="margin-left: 178px;">
            <div class="svg-container">
                <!-- First SVG (the circle) -->
                <svg class="background-svg" xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                  <circle cx="80" cy="80" r="80" fill="#A6DBD9"/>
                </svg>
              
                <!-- Second SVG (the path) -->
                <svg class="foreground-svg" xmlns="http://www.w3.org/2000/svg" width="99" height="99" viewBox="0 0 99 99" fill="none">
                  <path d="M74.464 84.8466C66.3204 84.8466 57.6769 83.1101 48.5847 79.6338C33.7635 73.3234 18.7868 73.3299 2.74637 79.6283C1.91506 79.9511 0.9816 79.5458 0.656683 78.7166C0.331766 77.8886 0.739271 76.9519 1.56841 76.6269C18.4369 70.0047 34.2101 70.0091 49.792 76.6443C67.0995 83.2589 82.6717 83.2796 96.0193 76.6824C96.817 76.2879 97.7852 76.615 98.1797 77.4126C98.5741 78.2113 98.247 79.1785 97.4494 79.5729C90.3349 83.0883 82.6532 84.8466 74.464 84.8466Z" fill="#123B63"/>
                  <path d="M98.1786 66.6653C97.7841 65.8666 96.8159 65.5406 96.0183 65.9351C92.1606 67.8422 88.1116 69.1777 83.8942 69.9797L98.0014 52.0364C98.3829 51.5518 98.4535 50.8911 98.184 50.3358C97.9145 49.7805 97.3505 49.4284 96.7333 49.4284H76.6297L66.7431 39.444L66.742 39.4429L56.3936 28.9923C56.0915 28.687 55.6785 28.5153 55.2482 28.5153H31.7042L27.7976 15.2023C27.5966 14.5156 26.9663 14.0439 26.2502 14.0439H16.8471C16.4081 14.0439 15.9876 14.2232 15.6833 14.5406C15.379 14.8579 15.2182 15.2849 15.2367 15.724L15.7724 28.5153H9.72937C8.9524 28.5153 8.28626 29.0684 8.14499 29.8323L6.2031 40.259V40.2612L4.49484 49.4295H2.15847C1.2674 49.4295 0.545842 50.2445 0.545842 51.1345C0.545842 56.5614 0.545842 60.9907 3.26037 65.2559C2.69638 65.4635 2.13457 65.6591 1.56841 65.8807C0.739271 66.2057 0.331766 67.1424 0.656683 67.9704C0.9816 68.7996 1.91506 69.2049 2.74637 68.8821C18.7868 62.5827 33.7635 62.5772 48.5847 68.8876C57.6769 72.3639 66.3204 74.1004 74.464 74.1004C76.3407 74.1004 78.188 73.9971 80.0115 73.8113C80.0375 73.8124 80.0625 73.8244 80.0875 73.8244C80.219 73.8244 80.3483 73.8048 80.4755 73.7733C86.4359 73.1169 92.1019 71.4684 97.4472 68.8267C98.2459 68.4312 98.573 67.464 98.1786 66.6653ZM18.5282 17.267H25.0429L28.3431 28.5142H18.9998L18.5282 17.267ZM11.0692 31.7383H30.4936H30.4969H30.5002H54.5756L61.7379 38.9713H9.72176L11.0692 31.7383ZM9.12083 42.1955H64.9305L72.0928 49.4284H7.77335L9.12083 42.1955ZM79.2801 70.6317C70.1618 71.4815 60.2991 69.9123 49.792 65.897C35.7162 59.9029 21.4796 59.3389 6.42369 64.1649C4.04278 60.8407 3.79719 57.2884 3.77219 52.6515H93.4157L79.2801 70.6317Z" fill="#123B63"/>
                  <path d="M75.8766 65.4894C72.8546 65.4894 70.3965 63.0314 70.3965 60.0093C70.3965 56.9883 72.8546 54.5303 75.8766 54.5303C78.8987 54.5303 81.3567 56.9883 81.3567 60.0093C81.3567 63.0325 78.8987 65.4894 75.8766 65.4894ZM75.8766 57.7555C74.6334 57.7555 73.6207 58.7672 73.6207 60.0104C73.6207 61.2536 74.6324 62.2663 75.8766 62.2663C77.1209 62.2663 78.1326 61.2546 78.1326 60.0104C78.1326 58.7672 77.1209 57.7555 75.8766 57.7555Z" fill="#123B63"/>
                </svg>
              </div>
        </div>
    </div>

    <!-- <div class="custom-scenario-evaluation">
        <div class="scenario-evaluation-txt-block">
            <label for="" class="se-txt-title">Custom Scenario Evaluation</label>
            <p class="se-txt-description">Custom Calculation enables exploration and comparison of climate impacts based on different decisions, such as steel sourcing, fuel use, and retrofitting. Adjusting these factors will influence the vessel’s lifecycle emissions, helping to tailor the analysis to specific needs and making informed decisions.</p>
        </div>

        
            <div class="customize-bulding-stage cse-content-cards-bloc">
                <label for="" class="cbs-title">Customize Building Stage</label>
    
                <div class="cbs-cards-bloc ">
                    <div class="cse-card-bloc" (click)="openModal('Steel Selection and Source')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Steel Selection and Source</label>
                                <img src="../../../assets/danish-shipping-assets/arrow-right-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario impacts the lifecycle emissions by accounting for the climate effects of steel production and transportation.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-warning-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-orange">In progress</label>
                        </div>
                    </div>
                    <div class="cse-card-bloc" (click)="openModal('Renewable Energy in Assembly')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Renewable Energy in Assembly</label>
                                <img src="../../../assets/danish-shipping-assets/refresh-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario assesses the impact of using renewable energy in assembly on lifecycle emissions.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-success-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-green">Completed/Repeat</label>
                        </div>
                    </div>
                    <div class="cse-card-bloc" (click)="openModal('Retrofit Modifications and Upgrades')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Retrofit Modifications and Upgrades</label>
                                <img src="../../../assets/danish-shipping-assets/refresh-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario examines how retrofitting different vessel components impacts lifecycle emissions.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-success-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-green">Completed/Repeat</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cse">
            <div class="cse-content-cards-bloc operational-impact-review">
                <label for="" class="cbs-title">Operational Impact Review</label>
    
                <div class="cbs-cards-bloc">
                    <div class="cse-card-bloc" (click)="openModal('Fuel Comparison')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Fuel Comparison</label>
                                <img src="../../../assets/danish-shipping-assets/arrow-right-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario evaluates how different fuel types impact the vessel's lifecycle emissions.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-secondary-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-gray">Not started</label>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="cse-content-cards-bloc vessel-retirement">
                <label for="" class="cbs-title">Vessel Retirement</label>
    
                <div class="cbs-cards-bloc">
                    <div class="cse-card-bloc" (click)="openModal('Vessel Recycling')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Vessel Recycling</label>
                                <img src="../../../assets/danish-shipping-assets/arrow-right-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario assesses the environmental impact of recycling the entire vessel at the end of its lifecycle.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-warning-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-orange">In progress</label>
                        </div>
                    </div>
                    <div class="cse-card-bloc" (click)="openModal('Sell Vessel')">
                        <div class="cse-card-content">
                            <div class="cse-card-title">
                                <label for="">Sell Vessel</label>
                                <img src="../../../assets/danish-shipping-assets/refresh-dark-icon.svg" alt="" class="">
                            </div>
                            <div class="cse-card-description">
                                <p class="cse-card-description-txt">This scenario assesses the climate benefits and responsibilities of selling a vessel, including avoided end-of-life and new build emissions.</p>
                            </div>
                                
                        </div>
                        <div class="cse-card-secondary-status">
                            <div class="dot-icon"></div>
                            <label for="" class="status-txt stat-txt-gray">Not started</label>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
      
    </div>

    <app-session-modal
        [isVisible]="isModalOpen"
        (closeEvent)="closeModal()"
        (confirmEvent)="handleConfirm($event)">
    </app-session-modal> -->
            
</div>
