import { Component, OnInit } from '@angular/core';
import { log } from 'console';
import { ApiIntegrationService } from 'src/app/services/api-integration.service';

@Component({
  selector: 'app-quima-usage',
  templateUrl: './quima-usage.component.html',
  styleUrls: ['./quima-usage.component.scss']
})
export class QuimaUsageComponent implements OnInit {
  public currentMonthUsage: number;
  public totalUsage: number;
  public usageCalculations: any[];
  public requestList:any[];
  public quimaRes:any[];
  public initialRequestList:any[];
  public listofHistory:any[];
  public x : number =0;
  public y : number =10;
  public pageNumber : number =1;
  public canShowUploadModal: Boolean=false; 
  public canShowHistoryModal: Boolean=false; 
  public showReqModal: Boolean=false; 
  public fileData: File = null;
  public selectedValidationRequestId:any;
  public fileName:String="Choose a file or drag it here";
  public keyword="";
  public uploadOption:String="";

  constructor(private ApiIntegration:ApiIntegrationService) { }

  ngOnInit(): void {
    this.showRequests();
  }
  public changepaginationValue(){
   
    if(this.y<this.usageCalculations.length +10){
      this.x= this.y ;
      this.y= this.y+10;
      this.pageNumber = this.pageNumber +1
    }
  }

  public changePaginationPrev(){
    if(this.pageNumber>1){
      this.x= this.x - 10 ;
      this.y= this.y -10;
      this.pageNumber = this.pageNumber -1
    }
  }

  public showRequests():void{
    this.ApiIntegration.getMaterialList().subscribe(res=>{
      this.quimaRes=res; ;
      this.currentMonthUsage = res.currentMonthUsage;
      this.totalUsage = res.totalUsage;
      this.usageCalculations = res.usageCalculations
      this.initialRequestList =this.requestList
    });
  } 

}
