<div class="vessels-header-container">
    <div class="nav-map-container" *ngIf="targetData?.showMap==true">
        <img class="home" src="../../../../../assets/danish-shipping-assets/home.svg" alt="">
        <ng-container *ngFor="let item of targetData?.mapElements;let idx=index">
            <img class="arrow" src="../../../../../assets/danish-shipping-assets/arrow-right.svg" alt="">
            <label class="nav-item" [ngClass]="{'nav-selected':idx+1==targetData?.mapElements.length}">{{item}}</label>
        </ng-container>
    </div>
    <div class="header-container">
        <div class="descriptive-container">
            <div class="title">{{targetData?.title}}</div>
            <div class="description">{{targetData?.description}}</div>
        </div>
        <div class="action-container" *ngIf="targetData?.action!=''">
            <button (click)="onAddVesselClick()" *ngIf="targetData?.action=='add-vessel'">
                <img src="../../../../../assets/danish-shipping-assets/add.svg" alt="">
                <label class="ml-2">Add vessel</label>
            </button>
            <button  *ngIf="targetData?.action=='download'" (click)="downloadPdf()">
                <img src="../../../../../assets/danish-shipping-assets/download.svg" alt="">
                <label class="ml-2">Download</label>
            </button>
        </div>
    </div>
</div>