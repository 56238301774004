import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsersService } from '../../../services/yardscore-services/users.service';

@Component({
  selector: 'app-ysa-delete-user',
  templateUrl: './ysa-delete-user.component.html',
  styleUrls: ['./ysa-delete-user.component.scss']
})
export class YsaDeleteUserComponent implements OnInit {

  @Input() currentUser:any=null;
  @Input() actionType:any=null;
  @Output() closeDeleteModal:any= new EventEmitter();

  constructor(
    private usersService:UsersService,
  ) { }

  ngOnInit(): void {
    if(this.actionType=='flipstatus'){
      if(this.currentUser.isActive==true){
        this.actionType="desactivate";
      }else{
        this.actionType="activate";
      }
    }
  }

  public proceed(){

    if(this.actionType=='delete'){
      // Delete user
      this.usersService.deleteUser(this.currentUser.id).subscribe(ans=>{
        this.closeDeleteModal.emit(true);
      },err=>{
        this.closeDeleteModal.emit(false);
      })
    }else{
      // Desactivate / activate
      const status={
        userId:this.currentUser.id,
        active:!this.currentUser.isActive
      }
      this.usersService.flipUserStatus(status).subscribe(ans=>{
        this.closeDeleteModal.emit(true);
      },err=>{
        this.closeDeleteModal.emit(false);
      })
    }
    
  }

  public cancel(){
    this.closeDeleteModal.emit(false);
  }
}
