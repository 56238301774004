import { createAction, props } from '@ngrx/store';

export const setVessels = createAction(
    '[Vessels] Set Vessels',
    props<{ vessels: any }>()
);

export const toggleVesselDrawer = createAction(
    "[VO - Header] toggle vessel drawer",
    props<{canShow:any}>()
);

export const createVessel = createAction(
    "[Vessel Drawer] create vessel",
    props<{vessel:any}>()
);
export const createVesselSuccess = createAction(
    "[Vessel Drawer] create vessel success",
    props<{createdVessel:any}>()
);
export const createVesselFailed = createAction(
    "[Vessel Drawer] create vessel failed",
    props<{error:any}>()
);



export const getActiveVessels= createAction(
    "[VO - Vessel impact] get active vessels",
    props<{id:any}>()
);
export const getActiveVesselSuccess = createAction(
    "[VO - Vessel impact] get vessels success",
    props<{vessels:any}>()
);
export const getActiveVesselFailed = createAction(
    "[VO - Vessel impact] get active vessels failed",
    props<{error:any}>()
);



export const getArchivedVessels = createAction(
    "[VO - Vessel impact] get archived vessels",
    props<{id:any}>()
);
export const getArchivedVesselSuccess = createAction(
    "[VO - Vessel impact] get archived vessels success",
    props<{vessels:any}>()
);
export const getArchivedVesselFailed = createAction(
    "[VO - Vessel impact] get archived vessels failed",
    props<{error:any}>()
);

export const archiveVessel = createAction(
    "[VO - Vessel card] archive vessel",
    props<{vessel:any}>()
);

export const archiveVesselSuccess = createAction(
    "[VO - Vessel card] archive vessel success",
    props<{archivedVessel:any}>()
);

export const archiveVesselFailed = createAction(
    "[VO - Vessel card] archive vessel failed",
    props<{error:any}>()
);



export const restoreVessel = createAction(
    "[VO - Vessel card] restore vessel",
    props<{vessel:any}>()
);
export const restoreVesselSuccess = createAction(
    "[VO - Vessel card] restore vessel success",
    props<{restoredVessel:any}>()
);
export const restoreVesselFailed = createAction(
    "[VO - Vessel card] restore vessel failed",
    props<{error:any}>()
);



export const getActiveStats = createAction(
    "[VO - Overview stats] get Active Stats",
    props<{id:any}>()
);
export const getActiveStatsSuccess = createAction(
    "[VO - Overview stats] get Active Stats success",
    props<{stats:any}>()
);
export const getActiveStatsFailed = createAction(
    "[VO - Overview stats] get Active Stats failed",
    props<{error:any}>()
);
export const getArchivedStats = createAction(
    "[VO - Overview stats] get Archived Stats",
    props<{id:any}>()
);
export const getArchivedStatsSuccess = createAction(
    "[VO - Overview stats] get Archived Stats success",
    props<{stats:any}>()
);
export const getArchivedStatsFailed = createAction(
    "[VO - Overview stats] get Archived Stats failed",
    props<{error:any}>()
);



export const importVesselData = createAction(
    "[Vessel drawer] import vessel data",
    props<{imo:any}>()
);
export const importVesselDataSuccess = createAction(
    "[Vessel drawer] import vessel data success",
    props<{newVesselData:any}>()
);
export const importVesselDataFailed = createAction(
    "[Vessel drawer] import vessel data failed",
    props<{error:any}>()
);





export const toggleView = createAction(
    "[VO - Vessel impact] toggle view",
    props<{viewType:any}>()
);
export const toggleSelectedTab = createAction(
    "[VO - tab switch] change selected tab",
    props<{selectedTab:any}>()
);

export const toggleLoadingActive = createAction(
    "[VO - loading indicatior] toggle loading active vessels",
    props<{canShow:any}>()
);

export const toggleLoadingArchived = createAction(
    "[VO - loading indicatior] toggle loading archived vessels",
    props<{canShow:any}>()
);

export const currentVessel = createAction(
    "[Vessel card] keep id of selected vessel",
    props<{vesselId:any}>()
);

export const initiateVesselLifeCycle = createAction(
    "[Vessel Lifecycle Simulator] start screening",
    props<{vesselId:any}>()
);
export const initiateVesselLifeCycleSuccess = createAction(
    "[Vessel Lifecycle Simulator] start screening success"
);
export const initiateVesselLifeCycleFailed = createAction(
    "[Vessel Lifecycle Simulator] start screening failed",
     props<{error:any}>()
);


export const initiateScenarios = createAction(
    "[Vessel Lifecycle Simulator] initiate scenarios",
    props<{vesselId:any}>()
);
export const initiateScenariosSuccess = createAction(
    "[Vessel Lifecycle Simulator] initiate scenarios success",
    props<{vesselLifecycleId:any}>()
);
export const initiateScenariosFailed = createAction(
    "[Vessel Lifecycle Simulator] initiate scenarios failed",
     props<{error:any}>()
);

export const downloadPdf = createAction(
    "[result page] download pdf",
     props<{prefix:any,vesselId:any}>()
);

export const downloadPdfSuccess = createAction(
    "[result page] download pdf success"
);

export const downloadPdfFailed = createAction(
    "[result page] download pdf failed",
     props<{error:any}>()
);


export const setVesselsOverviewState = createAction('[VesselsOverview] Set VesselsOverview', props<{ vesselsOverview: any }>());
export const clearVesselsOverviewState = createAction('[VesselsOverview] Clear VesselsOverview');


export const VesselsOverviewActions={
    setVesselsOverviewState,
    clearVesselsOverviewState,
    setVessels,
    downloadPdf,
    downloadPdfSuccess,
    downloadPdfFailed,
    initiateScenarios,
    initiateScenariosSuccess,
    initiateScenariosFailed,
    toggleLoadingActive,
    toggleLoadingArchived,
    toggleVesselDrawer,
    createVessel,
    createVesselSuccess,
    createVesselFailed,
    toggleView,
    toggleSelectedTab,
    getActiveVessels,
    getActiveVesselSuccess,
    getActiveVesselFailed,
    getArchivedVessels,
    getArchivedVesselSuccess,
    getArchivedVesselFailed,
    archiveVessel,
    archiveVesselSuccess,
    archiveVesselFailed,
    restoreVessel,
    restoreVesselSuccess,
    restoreVesselFailed,
    getActiveStats,
    getActiveStatsSuccess,
    getActiveStatsFailed,
    getArchivedStats,
    getArchivedStatsSuccess,
    getArchivedStatsFailed,
    importVesselData,
    importVesselDataSuccess,
    importVesselDataFailed,
    currentVessel
}