import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';


export interface UserState {
    currentUser:any,
    userInfo:any
}
  
const initialState: UserState = {
    currentUser:null,
    userInfo:null
    
};

export const UserReducer = createReducer(
    initialState,
    on(UserActions.loadCompanyId,  (state, { id }) => ({
        ...state, 
        companyId: id
    })),
    on(UserActions.registerCurrentUser,  (state, { user }) => ({
        ...state, 
        currentUser:user,
    })),
    on(UserActions.registerUserInfo,  (state, { user }) => ({
        ...state, 
        userInfo:user,
    })),
    on(UserActions.setUser, (state, { user }) => ({ 
        ...state, 
        currentUser : user.currentUser,
        userInfo : user.userInfo
    })),
    on(UserActions.clearUser, () => initialState)
);