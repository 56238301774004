import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { selectCreatedValidationRequest, selecterror, selectValidationContact } from '../../store/selectors/project.selectors';
import { filter, take } from 'rxjs/operators';
import { ProjectsActions } from '../../store/actions/project.actions';

@Component({
  selector: 'app-project-validation-modal',
  templateUrl: './project-validation-modal.component.html',
  styleUrls: ['./project-validation-modal.component.scss']
})

export class ProjectValidationModalComponent implements OnInit {

  @Input() currentProject:any=null;
  @Input() user:any=null;

  showFirst:boolean=true;
  showSubmitted:boolean=false;
  showLoading:boolean=false;
  showContact:boolean=false;
  currentUser:any=null;
  currentScreening:any=null;
  email:any=null;

  createdValidationRequest$:Observable<any>;
  error$:Observable<any>;
  validationContact$:Observable<any>;

  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private toasterService: ToastrService,
  ) { 
    this.createdValidationRequest$=projectsStore.select(selectCreatedValidationRequest)
    this.error$=projectsStore.select(selecterror)
    this.validationContact$=projectsStore.select(selectValidationContact)
    this.validationContact$.subscribe(ans=>{
      if(ans==null){
        this.projectsStore.dispatch(ProjectsActions.changeValidationContactSuccess({contact:this.user.email}))
      }
    })

  }

  ngOnInit(): void {
    
  }

  createVAlidationRequest(){
    this.showFirst=false;
    this.showLoading=true;
    this.projectsStore.dispatch(ProjectsActions.createValidationRequest({addValidationRequestDto:{
      screeningId:this.currentProject.id,
      userId:this.user.id
    }}));
    this.checkResults();
  }

  close(){
    this.projectsStore.dispatch(ProjectsActions.toggleValidationModal({canShow:false}))
  }

  showSecondModal(){

  }

  showContactModal(){
    this.showContact=true;
    this.showSubmitted=false;
  }

  saveContact(){
    this.createdValidationRequest$.subscribe(ans=>{
      this.projectsStore.dispatch(ProjectsActions.changeValidationContact({updateResponsibleDto:{
        validationRequestId:ans.id,
        email:this.email
      }}))
    })
  }


  checkResults(){
    this.checkError();
    this.createdValidationRequest$.pipe(
      filter(createdValidationRequest => !!createdValidationRequest), 
      take(1)
    ).subscribe(ans => {
      this.showFirst=false;
      this.showLoading=false;
      this.showSubmitted=true;
    });
  }
  checkResultEmailChange(){
    this.checkError();
    this.validationContact$.pipe(
      filter(validationContact => !!validationContact), 
      take(1)
    ).subscribe(ans => {
      this.toasterService.success('Contact changed successfully');
      this.close()
    });
  }
  checkError(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      this.toasterService.error('Operation failed');
      this.projectsStore.dispatch(ProjectsActions.toggleValidationModal({canShow:false}))
    });
  }
}
