import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyAssessmentService } from 'src/app/services/company-assessment.service';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.css']
})
export class WarningModalComponent implements OnInit {
  @Output() toggleWarningModal=new EventEmitter();
  @Output() proceedButtonClicked = new EventEmitter<void>(); 
  @Output() newItemEvent = new EventEmitter<string>();
  public uncompletedCategoryList:[];
  constructor(
    private companyAssessmentService:CompanyAssessmentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.companyAssessmentService.getUncompletedCategories(this.route.snapshot.paramMap.get('companyAssesssmentId')).subscribe(res=>{
      this.uncompletedCategoryList=res;
    });
  }

  public closeModal(){
    this.toggleWarningModal.emit(false);
  }

  public proceed() {
    this.proceedButtonClicked.emit();
    this.closeModal();
  }
  public sentedElementFromWarning(value){
    this.newItemEvent.emit(value);
    this.closeModal();
  }

}
