import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ScenariosState } from '../../store/reducers/scenarios.reducers';
import { ScenariosActions } from '../../store/actions/scenarios.actions';
import { Observable } from 'rxjs';
import { selectScenarioInventories, selectScenarioInventories_children, selectShowMaterialModal } from '../../store/selectors/scenarios.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-bls-step2',
  templateUrl: './bls-step2.component.html',
  styleUrls: ['./bls-step2.component.scss']
})
export class BlsStep2Component implements OnInit {

  showModal$:Observable<any>;
  scenarioInventories$:Observable<any>;
  scenarioInventories_children$:Observable<any>;
  children:any=null;
  currentScenario="Fuel";
  optionToEdit:any=null;
  parentId:any=0;
  @Input() fuelConsumptionData: any;


  constructor(
    private scenariosStore: Store<{ scenarios: ScenariosState }>,
  ) { 
    this.showModal$=scenariosStore.select(selectShowMaterialModal);
    this.scenarioInventories$=scenariosStore.select(selectScenarioInventories);
    this.scenarioInventories_children$=scenariosStore.select(selectScenarioInventories_children);
    this.scenarioInventories_children$.subscribe(ans=>{
      this.children=ans
    })
  }

  ngOnInit(): void {
    this.scenariosStore.dispatch(ScenariosActions.getScenarioInventories({id:this.fuelConsumptionData.id}));
    
  }

  addOption(param){
    this.currentScenario=param;
    this.optionToEdit=null;
    this.scenariosStore.dispatch(ScenariosActions.toggleMaterialModal({canShow:true}));
  }

  updateOption(option,ans){
    this.currentScenario="Fuel";
    setTimeout(() => {
      this.scenariosStore.dispatch(ScenariosActions.updateOption({option,isUpdating:ans}));
    }, 5000);
  }
  
  deleteOption(id){
    this.scenariosStore.dispatch(ScenariosActions.deleteOption({id}));
  }

}
