import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { UserState } from '../store/reducers/user.reducers';
import { Store } from '@ngrx/store';
import { catchError, map, take } from 'rxjs/operators';
import { selectCurrentUser } from '../store/selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ShippingGuard implements CanActivate {
  currentUser$:Observable<any>;
  constructor(
    private router: Router, 
    private auth: AuthGuard, 
    private userService: UserService,
    private store: Store<{ user: UserState }>,
    private toasterService: ToastrService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.select(selectCurrentUser).pipe(
      take(1), // Ensure we only get the latest value and complete the observable
      map((user) => {
        const hasAccess =
          user.access.filter((x) => x === 'Shipping').length > 0 &&
          user.type === 'Supplier';

        if (hasAccess) {
          return true; // Allow access
        } else {
          this.toasterService.error('Access denied');
          return this.router.createUrlTree(['/redirection']); // Redirect to a different route
        }
      })
    );
  }
}