import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DanishShippingComponent } from './danish-shipping.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResultsOverviewComponent } from './results-overview/results-overview.component';
import { EmissionNavigatorComponent } from './emission-navigator/emission-navigator.component';
import { VesselLifecycleScreeningComponent } from './vessel-lifecycle-screening/vessel-lifecycle-screening.component';
import { VesselLifecycleScreeningResultComponent } from './components/vessel-lifecycle-screening-result/vessel-lifecycle-screening-result.component';
import { CustomScenarioComponent } from './custom-scenario/custom-scenario.component';
import { ShippingGuard } from '../../auth/shipping.guard';


const routes: Routes = [
  {
    path: '',
    component: DanishShippingComponent,
    canActivate:[ShippingGuard],
    children:[
      {
        path: '',
        component: DashboardComponent,
        data:{title:"dashboard",action:"add-vessel"}
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data:{title:"dashboard",action:"add-vessel"}
      },
      {
        path: 'results-overview',
        component: ResultsOverviewComponent,
        data:{title:"Vessel Lifecycle Simulator",action:"download"}
      },
      { 
        path: ':vesselId/emission-navigator',
        component: EmissionNavigatorComponent,
        data:{title:"navigator",action:""}
      },
      { 
        path: ':vesselId/emission-navigator/:vessel_lcs_Id/vessel-lifecycle-screening',
        component: VesselLifecycleScreeningComponent,
        data:{title:"baselinescenario",action:""}
      },
      { 
        path: ':vesselId/vessel-lifecycle-screening-result/:vesselLifecycleId',
        component: VesselLifecycleScreeningResultComponent,
        data:{title:"baselinescenarioResult",action:"download"}
      },
      {
        path: 'custom-scenario',
        component: CustomScenarioComponent,
        data:{title:"Vessel Lifecycle Simulator"}
      }

    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DanishShippingRoutingModule { }
