import { Component, OnInit } from '@angular/core';
import { MaterialModalComponent } from '../components/material-modal/material-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScenariosState } from '../store/reducers/scenarios.reducers';
import { Store } from '@ngrx/store';
import { ScenariosActions } from '../store/actions/scenarios.actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { VesselsOverviewState } from '../store/reducers/vessels-overview.reducers';
import { selectCurrentVessel, selectCurrentVesselLifecycleId } from '../store/selectors/vessels-overview.selectors';
import { Router } from '@angular/router';
import { selectBaselineMetaData, selectBaseScenarioData, selectCurrentStep } from '../store/selectors/scenarios.selectors';
import { UserState } from '../../../store/reducers/user.reducers';
import { selectCompanyId } from '../../../store/selectors/user.selectors';
import { loadVesselsFromLocalStorage, saveScenariosToLocalStorage, saveVesselsToLocalStorage } from '../store/utils/scenario-storage.util';
import { VesselsOverviewActions } from '../store/actions/vessels-overview.actions';

@Component({
  selector: 'app-vessel-lifecycle-screening',
  templateUrl: './vessel-lifecycle-screening.component.html',
  styleUrls: ['./vessel-lifecycle-screening.component.scss']
})
export class VesselLifecycleScreeningComponent implements OnInit {

  vesselId$:Observable<any>;
  vesselLifecycleId$:Observable<any>;
  step1data$:Observable<any>;
  currentStep$:Observable<any>;
  vesselLifecycleScenarios$:Observable<any>;
  vesselLifecycleScenarios:any=null;
  activeTabIndex: number = 1; 
  currentVesselId:any=5;
  currentVesselLifecycleId:any=58;

    // Array to store rows
    options: any[] = [
      {
        type: 'Select Lubricating oil',
        weight: '',
        unit: 't',
        isEditable: false
      },
    
    ];
    private scenariosSubscription: Subscription;
    private vesselsSubscription: Subscription;
    constructor(
      private modalService: NgbModal,
      private router: Router,
      private scenariosStore: Store<{ scenarios: ScenariosState }>,
      private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,   
       private userStore: Store<{ user: UserState }>,
    ) {
      this.vesselId$=vesselsOverviewStore.select(selectCurrentVessel);
      this.vesselLifecycleId$=vesselsOverviewStore.select(selectCurrentVesselLifecycleId);
      
      this.vesselsSubscription = this.vesselsOverviewStore.select('vesselsOverview').subscribe((state) => {
        saveVesselsToLocalStorage(state); // Save `scenarios` to localStorage on update
      });
      const savedVessels = loadVesselsFromLocalStorage();
      if (savedVessels) {
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.setVessels({ vessels: savedVessels }));
      }
      combineLatest([
        this.vesselId$,
        this.vesselLifecycleId$,
      ]).subscribe(([vId, vlcId]) => {
        this.currentVesselId=vId;
        this.currentVesselLifecycleId=vlcId;
      });


      
      this.currentStep$=vesselsOverviewStore.select(selectCurrentStep);
      this.step1data$=scenariosStore.select(selectBaseScenarioData);
      this.vesselLifecycleScenarios$=scenariosStore.select(selectBaselineMetaData);
      
      this.currentStep$.subscribe(ans=>{
        this.activeTabIndex=ans;
        if (this.activeTabIndex > 2) {
          this.scenariosStore.dispatch(ScenariosActions.getVesselData({vesselId:this.currentVesselId}));
          this.userStore.select(selectCompanyId).subscribe(id=>{
            this.router.navigate([
              'homeServiceProvider',
              id,
              'danish-shipping',
              this.currentVesselId,
              'vessel-lifecycle-screening-result',
              this.currentVesselLifecycleId
            ]);
          })
          //this.router.navigate(['/danish-shipping/vessel-lifecycle-screening-result']);
        }
      })
      this.vesselLifecycleScenarios$.subscribe(ans=>{
        this.vesselLifecycleScenarios=ans
      })


    }
  


  ngOnInit(): void {
    this.vesselId$.subscribe(ans=>{
      this.scenariosStore.dispatch(ScenariosActions.fetchBaselineBuildingScenario({id:this.currentVesselId}));
      this.scenariosStore.dispatch(ScenariosActions.fetchBaseScenarios({id:this.currentVesselLifecycleId}));
      
    })
  }


    // Method to set the active tab
    setActiveTab(index: number): void {
      this.activeTabIndex = index;
    }
  
    // Method to go to the next tab
    goToNextTab(): void {
      this.scenariosStore.dispatch(ScenariosActions.confirmStep({scenarioId:this.vesselLifecycleScenarios[this.activeTabIndex].id}))
      
    }
  

    addOption(): void {
      this.options.push({
        type: 'Select Lubricating oil',
        weight: '',
        unit: 't',
        isEditable: true
      });
    }


    openMaterialModal(): void {
      const modalRef = this.modalService.open(MaterialModalComponent, { size: 'lg' });
      modalRef.componentInstance.title = 'Materials'; // Set title
      modalRef.componentInstance.material = { // Pre-fill the modal with fake data
        name: 'Diesel Fuel',
        weight: 10,
        unit: 't'
      };
      modalRef.componentInstance.sharedComponents = [ // Add fake shared components
        {
          name: 'Shared Component 1',
          company: { companyName: 'Company A' },
          note: 'Note 1',
          weight: 5,
          emission: 20.5
        },
        {
          name: 'Shared Component 2',
          company: { companyName: 'Company B' },
          note: 'Note 2',
          weight: 15,
          emission: 50.3
        }
      ];
    
      modalRef.result
        .then((result) => {
          if (result) {
            this.options.push({
              type: result.name || 'Custom Fuel Option',
              weight: result.weight || '',
              unit: result.unit || 't',
              isEditable: true
            });
          }
        })
        .catch((dismissed) => {
        });
    }
    

    deleteOption(index: number): void {
      this.options.splice(index, 1);
    }
    
    ngOnDestroy() {
      this.vesselsSubscription.unsubscribe();
    }
}
