import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VesselsOverviewState } from '../store/reducers/vessels-overview.reducers';
import { selectActiveStats, selectActiveVessels, selectArchivedStats, selectArchivedVessels, selectCanShowVesselDrawer, selectCountActive, selectCountArchived, selectTab, selectViewType } from '../store/selectors/vessels-overview.selectors';
import { VesselsOverviewActions } from '../store/actions/vessels-overview.actions';
import { UserState } from '../../../store/reducers/user.reducers';
import { selectCompanyId } from '../../../store/selectors/user.selectors';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  canShowVesselDrawer$:Observable<any>;
  viewType$:Observable<any>;
  selectedTab$:Observable<any>;
  activeVessels$:Observable<any>;
  archivedVessels$:Observable<any>;
  activeStats$:Observable<any>;
  archivedStats$:Observable<any>;
  countActive$:Observable<any>;
  countArchived$:Observable<any>;

  isIconVisible: boolean = true;
  isListView: boolean = false; 
  showDrawer:boolean=false;
  activeTab: string = '';
  companyId:any=null;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private userStore: Store<{ user: UserState }>,
  ) {
    this.canShowVesselDrawer$=vesselsOverviewStore.select(selectCanShowVesselDrawer);
    this.viewType$=vesselsOverviewStore.select(selectViewType);
    this.selectedTab$=vesselsOverviewStore.select(selectTab);
    this.selectedTab$.subscribe(ans=>{
      
    })
    this.activeVessels$=vesselsOverviewStore.select(selectActiveVessels);
    this.archivedVessels$=vesselsOverviewStore.select(selectArchivedVessels);
    this.activeStats$=vesselsOverviewStore.select(selectActiveStats);
    this.archivedStats$=vesselsOverviewStore.select(selectArchivedStats);
    this.countActive$=vesselsOverviewStore.select(selectCountActive);
    this.countArchived$=vesselsOverviewStore.select(selectCountArchived);
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleLoadingActive({canShow:true}));
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleLoadingArchived({canShow:true}));
   }

  ngOnInit(): void {
    this.userStore.select(selectCompanyId).subscribe(ans=>{
      this.companyId=ans;
      if(ans){
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveStats({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveVessels({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedStats({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedVessels({id:this.companyId}));
      }
    })
  }

    
  toggleView(view: string) {
    //this.isListView = view === 'list';
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleView({viewType:view}))
  
  }

  setActiveTab(tab: string): void {
    //this.activeTab = tab;
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleSelectedTab({selectedTab:tab}))
  }


  public openDrawer(event){

  }

  navigateToEmissionNavigator(): void {
   
    this.router.navigate(['emission-navigator'], { relativeTo: this.route });
  }

 
}
