import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireService } from '../../../services/yardscore-services/questionnaire.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  public selectedQuestionType:any="Select Question Type";
  public canAddMore:boolean=false;
  public subcategory_id:any=null;
  public questionsList:any=[
    
  ];
  public error="";
  public showError:boolean=false;

  constructor(
    private questionnaireService:QuestionnaireService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subcategory_id = this.route.snapshot.paramMap.get('subcategory_id');
    this.getQuestions();
  }

  public selectType(type,index){
    this.questionsList[index].id=0;
    this.questionsList[index].questionTitle="";
    this.questionsList[index].questionType=type;
    this.questionsList[index].subCategoryId=this.subcategory_id;
    this.questionsList[index].canAddMore=true;
    this.questionsList[index].questionnaireQuestionOptions=[];
    if(type=='YES / NO'){
      this.questionsList[index].canAddMore=false;
      this.questionsList[index].questionnaireQuestionOptions=[
        {
          id:0,
          answerTitle:"YES",
          answerScore:"",
          questionnaireQuestionId:0
        },{
          id:0,
          answerTitle:"NO",
          answerScore:"",
          questionnaireQuestionId:0
        }
      ];
    }else{
      this.questionsList[index].questionnaireQuestionOptions=[{
        id:0,
        answerTitle:"",
        answerScore:"",
        questionnaireQuestionId:0
      }];
    }
  }

  public addEmptyQuestion(){
    this.questionsList.push({
      id:0,
      questionTitle:"",
      questionType:"",
      subCategoryId:0,
      questionnaireQuestionOptions:[
        {
          id:0,
          answerTitle:"",
          answerScore:"",
          questionnaireQuestionId:0
        }
      ]
    });
  }

  public addAnswer(idx){
    this.questionsList[idx].questionnaireQuestionOptions.push( {
      id:0,
      answerTitle:"",
      answerScore:"",
      questionnaireQuestionId:0
    });
  }

  public saveQuestion(index,type){
    if(type=='Dropdown'){
      if(this.questionsList[index].questionnaireQuestionOptions.length<2){
        this.showError=true;
        this.error="Select at least 2 options";
        return;
      }
    }
    const question=this.questionsList[index];
    delete question.canAddMore;
    this.questionnaireService.createQuestionnaire(question).subscribe(ans=>{
      this.questionsList[index]=ans;
    },err=>{
    })
  }

  public getQuestions(){
    this.questionnaireService.getQuestionnaires(this.subcategory_id).subscribe(ans=>{
      this.questionsList=ans;
    },err=>{
    })
  }

  public saveAll(){
    for (let index = 0; index < this.questionsList.length; index++) {
      const question=this.questionsList[index];
      delete question.canAddMore;
      this.questionnaireService.createQuestionnaire(question).subscribe(ans=>{
        this.questionsList[index]=ans;
      },err=>{
      })
    }
  }

}
