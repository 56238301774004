import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '../../../services/yardscore-services/categories.service';

@Component({
  selector: 'app-questionnaire-categories',
  templateUrl: './questionnaire-categories.component.html',
  styleUrls: ['./questionnaire-categories.component.scss']
})
export class QuestionnaireCategoriesComponent implements OnInit {

  public showUpsertForm:boolean=false;
  public showDeleteForm:boolean=false;
  public actionType="";
  public actionOn="";
  public questionnaireType:any="";
  public categories:any=null;
  public currentCategory:any=null;
  public toSortBy="Name";

  constructor(
    private route: ActivatedRoute,
    private categoriesService:CategoriesService,
  ) { }

  ngOnInit(): void {
    this.questionnaireType = this.route.snapshot.paramMap.get('questionnaire_type');
    this.getCategories();
    
  }

  public closeUpsertForm(event){
    this.showUpsertForm=false;
    this.getCategories();
  }

  public toggleUpsertForm(action,category){
    this.showUpsertForm=true;
    this.currentCategory=category;
    this.actionType=action;
  }

  public closeDeleteModal(event){
    this.showDeleteForm=false;
    this.getCategories();
  }

  public toggleDeleteForm(item){
    this.currentCategory=item;
    this.showDeleteForm=true;
  }

  public getCategories(){
    this.categoriesService.getCategories(this.questionnaireType).subscribe(ans=>{
      this.categories=ans;
    },err=>{
    })
  }
  
}
