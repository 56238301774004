import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HelperService } from '../helper.service';
import { HttpClient } from '@angular/common/http';
import { FuelOption } from 'src/app/models/fuel-option.model';

@Injectable({
  providedIn: 'root'
})
export class VesselCalculationInventoryService {

  
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public addLCS(createLifeCycleScenariosDto: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/add-life-cycle-scenarios`,
      createLifeCycleScenariosDto,
      this.helperService.headers()
    );
  }

  public getOptionDataForLifeCycleScenario(title: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/options-lifecycle/${title}`,
      this.helperService.headers()
    );
  }
 

  public getFuelConsumptionAndType(title: any,id:any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/options-lifecycle/${title}`,
      this.helperService.headers()
    );
  }

  public addScenarioInventory(option: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/add-scenario-inventory`,
      option,
      this.helperService.headers()
    );
  }

  public updateScenarioInventory(option: any): Observable<any> {
    return this.http.put<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/update-scenario-inventory/${option.id}`,
      option,
      this.helperService.headers()
    );
  }

  public deleteScenarioInventory(id: any): Observable<any> {
    return this.http.delete<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/delete-scenario-inventory/${id}`,
      this.helperService.headers()
    );
  }

  public getOptionsByLifeCycleScenarioId(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/options/${id}`,
      this.helperService.headers()
    );
  }

  public confirmLifeCycleScenario(id: any): Observable<any> {
    return this.http.put<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/confirm-life-cycle-scenario/${id}`,
      this.helperService.headers()
    );
  }

  public getScope1Impact(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/scope1-impact/${id}`,
      this.helperService.headers()
    );
  }

  public getScope2Impact(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/scope2-impact/${id}`,
      this.helperService.headers()
    );
  }

  public getScope3Impact(id: any,vesselType): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/${id}/scope3-${vesselType}`,
      this.helperService.headers()
    );
  }
  public getFuelOptions(): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/getFuelOptions`,
      this.helperService.headers()
    );
  }
  public addFuelOption(fuelOption: FuelOption): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/addFuelOption`, 
      fuelOption,
      this.helperService.headers() 
    );
  }
  deleteFuelOption(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/deleteFuelOption/${id}`
    );
  }
  updateFuelOption(fuelOption: FuelOption): Observable<any> {
    return this.http.put<any>(
      `${this.environmentUrl}/api/vessel-calculation-inventory/updateFuelOption/${fuelOption.id}`,
      fuelOption
    );
  }
  
  

}
