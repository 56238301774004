import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FoldersState } from '../../../store/reducers/folder.reducer';
import { ProjectsState } from '../../../store/reducers/project.reducer';
import { ProjectsActions } from '../../../store/actions/project.actions';
import { FoldersActions } from '../../../store/actions/folder.actions';
import { Observable } from 'rxjs';
import { selectCreatedValidationRequest, selectDownload, selectDuplicatedProject, selecterror, selectHistory, selectSharedProject, selectValidationRequest } from '../../../store/selectors/project.selectors';
import { filter, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-completed-details',
  templateUrl: './completed-details.component.html',
  styleUrls: ['./completed-details.component.scss']
})
export class CompletedDetailsComponent implements OnInit,OnChanges {

  @Input() currentProject:any=null;

  error$:Observable<any>;
  sharedProject$:Observable<any>;
  duplicatedProject$:Observable<any>;
  validationRequest$:Observable<any>;
  createdValidationRequest$:Observable<any>;
  download$:Observable<any>;

  validationRequest:any=null;
  projectRequest:any=null;
  requestStatus:any=null;
  statusIcon:any=null;
  projectHistory:any=null;
  
  loadingShare:boolean=false;
  loadingDuplicate:boolean=false;
  downloadingFile:boolean=false;

  public projectInfo=[
    {stateValue:false,state:"screening-setup",name:"Project Setup"},
    {stateValue:false,state:"system-definition",name:"System Definition"},
    {stateValue:false,state:"screening-inventory",name:"Product Inventory"},
    {stateValue:false,state:"review",name:"Project Review"},
  ];

  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private foldersStore: Store<{ folders: FoldersState }>,
    private toasterService: ToastrService,
    private router: Router
  ) { 
    this.error$ = projectsStore.select(selecterror);
    this.download$ = projectsStore.select(selectDownload);
    this.duplicatedProject$ = projectsStore.select(selectDuplicatedProject);
    this.validationRequest$=projectsStore.select(selectValidationRequest);
    this.createdValidationRequest$=projectsStore.select(selectCreatedValidationRequest);
    foldersStore.select(selectHistory).subscribe(ans=>{
      this.projectHistory=ans;
    });

  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.loadValidationAndHistory();
  }

  loadValidationAndHistory(){
    this.projectsStore.dispatch(ProjectsActions.getValidationRequest({id:this.currentProject.id}));
    this.projectsStore.dispatch(ProjectsActions.getHistory({id:this.currentProject.id}));
    this.obtainValiudationRequest();
  }

  downloadSheet(){
    this.projectsStore.dispatch(ProjectsActions.noDownloads());
    this.downloadingFile=true;
    this.projectsStore.dispatch(ProjectsActions.downloadExcelSheet({id:this.currentProject.id,name:this.currentProject.name}));
    this.checkDownload()
  }

  downloadPdf(){
    this.projectsStore.dispatch(ProjectsActions.noDownloads());
    this.downloadingFile=true;
    this.projectsStore.dispatch(ProjectsActions.downloadPdf({id:this.currentProject.id,name:this.currentProject.name}));
    this.checkDownload()
  }

  downloadCertificate(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.validationRequest.validationCertificate);
    link.setAttribute('download', this.currentProject.name+`.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  share(){
    this.projectsStore.dispatch(ProjectsActions.toggleShareModal({canShow:true}));
   
  }

  duplicate(){
    this.loadingDuplicate=true;
    this.projectsStore.dispatch(ProjectsActions.duplicateProject({id:this.currentProject.id}));
    this.checkDuplicate()
  }

  move(){
    this.projectsStore.dispatch(ProjectsActions.toggleMoveProject({canMoveProject:true}));
  }

  toggleValidationModal(){
    this.projectsStore.dispatch(ProjectsActions.toggleValidationModal({canShow:true}));
    this.checkValidation();
  }

  archive(){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:true,
          archiveOperation:'archive',
          folderName:this.currentProject.name,
          targetId:this.currentProject.id,
          targetType:'project',
          source:""
        }
      }
    ))
  }
  obtainValiudationRequest(){
    this.validationRequest$.pipe(
      filter(validationRequest => !!validationRequest), 
      take(1)
    ).subscribe(validationRequest => {
      this.validationRequest=validationRequest;
      this.projectRequest=validationRequest.request;
      if(this.projectRequest.status=="received"||this.projectRequest.status=="Received"){
        this.requestStatus="Validation request received";
        this.statusIcon="icon-validation validation";
      }
      else if(this.projectRequest.status=="in progress"){
        this.requestStatus="Validating";
        this.statusIcon="icon-in-progress orange";
      }
      else if(this.projectRequest.status=="corrected"){
        this.requestStatus="Awaiting correction";
        this.statusIcon="icon-edit purple";
      }
      else if(this.projectRequest.status=="completed"){
        this.requestStatus="Validated";
        this.statusIcon="icon-validation-completed blue";
      }
      
    });
  }

  checkValidation(){
    this.createdValidationRequest$.pipe(
      filter(createdValidationRequest => !!createdValidationRequest), 
      take(1), tap((result) => {})
    ).subscribe(result => {
      this.requestStatus="Validation request received";
      this.statusIcon="icon-validation validation";
      this.loadValidationAndHistory();
    });
  }

  

  checkDuplicate(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      if(this.loadingDuplicate==true){
        this.loadingDuplicate=false;
        this.toasterService.error('Operation failed');
      }
    });
    this.duplicatedProject$.pipe(
      filter(duplicatedProject => !!duplicatedProject), 
      take(1)
    ).subscribe(result => {
        this.loadingDuplicate=false;
        this.toasterService.success('Project duplicated successfully');
        this.router.navigate(['homeServiceProvider',result.companyId,'quantify','screening', result.id]);
    });
  }

  checkDownload(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      if(this.downloadingFile==true){
        this.downloadingFile=false;
        this.toasterService.error('Download failed');
      }
    });
    this.download$.pipe(
      filter(download => !!download), 
      take(1)
    ).subscribe(result => {
        this.downloadingFile=false;
        this.toasterService.success('File downloaded successfully');
    });
  }
    

}
