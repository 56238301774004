import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { ProjectsState } from '../reducers/project.reducer';

export const selectProjectsState = createFeatureSelector<AppState,ProjectsState>('projects');

export const selectEmptyProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state?.newEmptyProject
);

export const selectsSearchResult = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.searchResult
);


export const selectsDraftProjects = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.draftProjects
);

export const selectsCompletedProjects = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.completedProjects
);

export const selectsArchivedProjects = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.archivedProjects
);

export const selectsSubFolderProjects = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.subfolderProjects
);


export const selectLoadingMore = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.loadingMore
);
export const selectloadingCount = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.loadingCount
);

export const selectSelectedProjectt = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.selectedProject
);
export const selectResultProjectt = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.resultProject
);
export const selectShowMovingFolder = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showMovingFolder
);
export const selectLoadingResultProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.loadingResultProject
);
export const selectShowMoveProjectModal = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showMoveProjectModal
);

export const selectAssignedProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.assignedProject
);


export const selecterror = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.error
);

export const selectSharedProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.sharedProject
);

export const selectDuplicatedProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.duplicatedProject
);

export const selectValidationRequest = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.validationRequest
);

export const selectHistory = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.projectHistory
);

export const selectDownload = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.download
);

export const selectShowValidationModal = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showValidationModal
);

export const selectCreatedValidationRequest = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.createdValidationRequest
);

export const selectValidationContact = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.validationContact
);

export const selectshowImportProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showImportProject
);

export const selectImportedProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.importedProject
);
 
export const selectShowShareModal = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showShareModal
);

export const selectShowRefillModal = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.showRefillModal
);

export const selectCustomerData = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.customerData
);