import { Component, OnInit } from '@angular/core';
import { SubcategoriesService } from '../../../services/yardscore-services/subcategories.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-questionnaire-subcategories',
  templateUrl: './questionnaire-subcategories.component.html',
  styleUrls: ['./questionnaire-subcategories.component.scss']
})
export class QuestionnaireSubcategoriesComponent implements OnInit {

  public showUpsertForm:boolean=false;
  public showDeleteForm:boolean=false;
  public actionType="";
  public module_id:any=null;
  public subcategories:any=null;
  public currentSubcategory:any=null;
  public toSortBy="Date";

  constructor(
    private route: ActivatedRoute,
    private subcategoriesService:SubcategoriesService
  ) { }

  ngOnInit(): void {
    this.module_id = this.route.snapshot.paramMap.get('module_id');
    
    this.getSubcategories();
  }

  public closeUpsertForm(event){
    this.showUpsertForm=false;
    this.getSubcategories();
  }

  public toggleUpsertForm(action,subcategory){
    this.showUpsertForm=true;
    this.currentSubcategory=subcategory;
    this.actionType=action;
  }

  public closeDeleteModal(event){
    this.showDeleteForm=false;
    this.getSubcategories();
  }

  public toggleDeleteForm(item){
    this.showDeleteForm=true;
    this.currentSubcategory=item;
  }

  public getSubcategories(){
    this.subcategoriesService.getSubcategories(this.module_id).subscribe(ans=>{
      this.subcategories=ans;
    },err=>{
    })
  }
  
}
