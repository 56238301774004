import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VesselsService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public createVessel(createScreeningDto: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessels/save`,
      createScreeningDto,
      this.helperService.headers()
    );
  }

  public getActiveVessels(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/active/`+id,
      this.helperService.headers()
    );
  }

  public getArchivedVessels(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/archived/`+id,
      this.helperService.headers()
    );
  }

  public archiveVessel(id: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessels/archive/`+id,
      this.helperService.headers()
    );
  }

  public restoreVessel(id: any): Observable<any> {
    return this.http.post<any>(
      `${this.environmentUrl}/api/vessels/activate/`+id,
      this.helperService.headers()
    );
  }

  public getActiveStats(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/active-overview/`+id,
      this.helperService.headers()
    );
  }
  public getArchivedStats(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/archived-overview/`+id,
      this.helperService.headers()
    );
  }

  public importNewVesselData(imo: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/find/`+imo,
      this.helperService.headers()
    );
  }
  public importVesselData(imo: any): Observable<any> {
    return this.http.get<any>(
      `${this.environmentUrl}/api/vessels/importVesselData/`+imo,
      this.helperService.headers()
    );
  }
}
