import { Component, OnInit } from '@angular/core';
import { FuelOption } from 'src/app/models/fuel-option.model';
import { VesselCalculationInventoryService } from 'src/app/services/danish-shipping-services/vessel-calculation-inventory.service';

@Component({
  selector: 'app-fuel-list',
  templateUrl: './fuel-list.component.html',
  styleUrls: ['./fuel-list.component.css']
})
export class FuelListComponent implements OnInit {
  fuelOptions: any[] = [];
  showFuelOptionModal: boolean = false;
  showEditModalForm: boolean = false; 
  selectedFuelOption: any; 



  constructor(private fuelService:VesselCalculationInventoryService) { }

  ngOnInit(): void {
    this.loadFuelOptions();

  }
  loadFuelOptions(): void {
    this.fuelService.getFuelOptions().subscribe(
      (data) => {
        this.fuelOptions = data;
      },
      (error) => {
        console.error('Error loading fuel options:', error);
      }
    );
  }
  public showAddModal(isShow: boolean) {
    this.showFuelOptionModal = isShow;
  }
  showEditeModal(fuelOption: FuelOption): void {
    this.selectedFuelOption = fuelOption;
    this.showEditModalForm = true;
  }
  deleteFuelOption(fuelId: string): void {
    const confirmed = window.confirm(
      `This action will permanently delete the selected fuel option.\n\nDo you want to continue?`
    );
    if (confirmed) {
      this.fuelService.deleteFuelOption(fuelId).subscribe(
        () => {
          this.loadFuelOptions();
        },
        (error) => {
          console.error('Error deleting fuel option:', error);
        }
      );
    }
  }
  
}
