import { createReducer, on } from '@ngrx/store';
import { FoldersActions } from '../actions/folder.actions';
import { UpsertFolderProps } from '../models/upsert-folder-props';
import { Folder } from '../models/folder';
import { ProjectsActions } from '../actions/project.actions';

export interface ProjectsState {
    recentProjects: Array<any>,

    searchResult: Array<any>,

    draftProjects: Array<any>,
    completedProjects: Array<any>,
    archivedProjects: Array<any>,
    subfolderProjects: Array<any>,

    newEmptyProject:any,
    lastProjectsDispatcher:any,
    selectedProject:any,
    resultProject:any,
    loadingResultProject:boolean | null,
    assignedProject:any,
    sharedProject:any,
    duplicatedProject:any,
    validationRequest:any,
    importedProject:any,
    createdValidationRequest:any,
    validationContact:any,
    projectHistory:any,
    customerData:any,
    download:any | null,

    showMovingFolder:boolean | null,
    showImportProject:boolean | null,
    showMoveProjectModal:boolean | null,
    showValidationModal:boolean | any,
    showShareModal:boolean | any,
    showRefillModal:boolean | any,
    loadingRecent: boolean | null,
    loadingSearch: boolean | null,
    loadingMore: boolean | null,
    loadingCount: any,
    error: any | null,

}
  
const initialState: ProjectsState = {
    recentProjects: [],
    searchResult:[],
    draftProjects:[],
    completedProjects:[],
    archivedProjects:[],
    subfolderProjects:[],
    newEmptyProject:null,
    lastProjectsDispatcher:{ folderName: '' },
    selectedProject:null,
    loadingResultProject: false,
    
    resultProject:null,
    assignedProject:null,
    sharedProject:null,
    duplicatedProject:null,
    validationRequest:null,
    importedProject:null,
    createdValidationRequest:null,
    validationContact:null,
    projectHistory:null,
    customerData:null,
    download:"",

    showMovingFolder:false,
    showImportProject:false,
    showMoveProjectModal:false,
    showValidationModal:false,
    showShareModal:false,
    showRefillModal:false,
    loadingRecent: false,
    loadingSearch: false,
    loadingMore:false,
    loadingCount:0,
    error:null
};

export const ProjectReducer = createReducer(
    initialState,

    on(ProjectsActions.initState, (state) => ({
      ...initialState
    })),
    // load Recent Projects failed
    on(ProjectsActions.loadRecentProjectsFailed, (state, { error }) => ({
        ...state,
        loadingRecent: false,
        error,
      })),

    //  load Recent Projects SUCCESS
    on(ProjectsActions.loadRecentProjectsSuccess, (state, { recentProjects }) => ({
        ...state,
        recentProjects: [...state.recentProjects, ...recentProjects],
        loading: false,
        error: null,
      })),


    on(ProjectsActions.setLoading, (state, { loadingRecent }) => ({
        ...state,
        loadingRecent
    })),

    on(ProjectsActions.createNewProjectSuccess, (state, { newEmptyProject }) => ({
        ...state,
        newEmptyProject: newEmptyProject,
        loading: false,
        error: null,
      })),
      on(ProjectsActions.createNewProjectFailed, (state, { error }) => ({
        ...state,
        newEmptyProject: null,
        loading: false,
        error,
      })),

      on(ProjectsActions.searchProjectSuccess, (state, { searchResult }) => ({
        ...state,
        searchResult:[...searchResult],
        loading: false,
        error:null,
      })),
      on(ProjectsActions.searchProjectFailed, (state) => ({
        ...state,
        searchResult:[],
        loading: false,
      })),

      on(ProjectsActions.loadingSearch, (state, { loadingSearch }) => ({
        ...state,
        loadingSearch,
      })),
      on(ProjectsActions.toggleLoadingMore, (state) => ({
        ...state,
        loadingMore:!state.loadingMore,
      })),
      on(ProjectsActions.trackLastProjectsDispatcher, (state, { node }) => {
        let loadingCount: number;
      
        switch (node.folderName) {
          case "Draft":
            loadingCount = state.draftProjects.length;
            break;
          case "Completed":
            loadingCount = state.completedProjects.length;
            break;
          case "Archived":
            loadingCount = state.archivedProjects.length;
            break;
          default:
            loadingCount =
              node?.folderName == state.lastProjectsDispatcher?.folderName
                ? state.subfolderProjects.length
                : 0;
            break;
        }
        return {
          ...state,
          loadingCount,
          lastProjectsDispatcher: node,
        };
      }),


      on(ProjectsActions.loadMoreSuccess, (state, { projects }) => {
        switch (state.lastProjectsDispatcher.folderName) {
          case "Draft":
            return{
              ...state,
              draftProjects:[...state.draftProjects,...projects],
              loadingCount:state.draftProjects.length+projects.length,
              loadingMore:false
            }
          case "Completed":
            return{
              ...state,
              completedProjects:[...state.completedProjects,...projects],
              loadingCount:state.completedProjects.length+projects.length,
              loadingMore:false
            }
          case "Archived":
            return{
              ...state,
              archivedProjects:[...state.archivedProjects,...projects],
              loadingCount:state.archivedProjects.length+projects.length,
              loadingMore:false
            }
          default:
            return state;
        }
        return state;
      }),
      on(ProjectsActions.loadMoreOfSubfolderSuccess, (state, { projects }) => ({
        ...state,
        subfolderProjects:[...state.subfolderProjects,...projects],
        loadingCount:state.subfolderProjects.length+projects.length,
        loadingMore:false
      })),
      on(ProjectsActions.selectedProject, (state, { project }) => ({
        ...state,
        selectedProject:project,
        resultProject:null,
        validationRequest:null,
        projectHistory:null,
        loadingResultProject:true,
      })),
      on(ProjectsActions.fetchSelectedProjectSuccess, (state, { project }) => ({
        ...state,
        resultProject:project,
        loadingResultProject:false,
      })),
      on(ProjectsActions.fetchSelectedProjectFailed, (state, { error }) => ({
        ...state,
        resultProject:null,
        loadingResultProject:false,
      })),
      on(ProjectsActions.toggleMoveProject, (state,{canMoveProject}) => ({
        ...state,
        showMovingFolder: canMoveProject,
      })),
      on(ProjectsActions.showMoveProjectModal, (state,{canShow}) => ({
        ...state,
        showMoveProjectModal: canShow,
        assignedProject:null,
      })),
      on(ProjectsActions.moveToFolderSuccess, (state, { project }) => {
        let dispatcherKey:any=null;
        let folder = state.lastProjectsDispatcher.folderName; 
        if(folder=='Draft'){
          dispatcherKey="draftProjects"
        }else if(folder=='Completed'){
          dispatcherKey="completedProjects"
        }else{
          dispatcherKey="subfolderProjects"
        }
        const updatedArray = [...state[dispatcherKey], project];
        return {
          ...state,
          assignedProject: project,
          draftProjects: state.draftProjects.filter(x => x.id !== project.id),
          completedProjects: state.completedProjects.filter(x => x.id !== project.id),
          subfolderProjects: state.subfolderProjects.filter(x => x.id !== project.id),
          [dispatcherKey]: updatedArray, // Dynamically update the targeted array
          showMovingFolder: false,
        };
      }),
      on(ProjectsActions.moveToFolderError, (state,{error}) => ({
        ...state,
        assignedProject:null,
        showMovingFolder:false,
        showMoveProjectModal:false,
        error
      })),
      on(ProjectsActions.clearProjects, (state) => ({
        ...state,
        subfolderProjects:[],
      })),
      on(ProjectsActions.clearSubfolderProjects, (state) => ({
        ...state,
        subfolderProjects:[],
      })),
      on(ProjectsActions.spliceProject, (state,{key,id}) => ({
        ...state,
        [key]:state[key].filter(x=>x.id!=id),
      })),

      on(ProjectsActions.shareProjectSuccess, (state,{project}) => ({
        ...state,
        sharedProject:project,
        error:null
      })),
      on(ProjectsActions.shareProjectFailed, (state,{error}) => ({
        ...state,
        sharedProject:null,
        error:error
      })),

      on(ProjectsActions.duplicateProjectSuccess, (state,{project}) => ({
        ...state,
        duplicatedProject:project,
        draftProjects:[{id:project.id,name:project.name},...state.draftProjects],
        error:null
      })),
      on(ProjectsActions.duplicateProjectFailed, (state,{error}) => ({
        ...state,
        duplicatedProject:null,
        error:error
      })),
      on(ProjectsActions.getValidationRequestSuccess, (state,{request}) => ({
        ...state,
        validationRequest:request,
      })),
      on(ProjectsActions.getHistorySuccess, (state,{history}) => ({
        ...state,
        projectHistory:history,
      })),
      on(ProjectsActions.downloadSuccess, (state) => ({
        ...state,
        download:true,
        error:null
      })),
      on(ProjectsActions.downloadFailed, (state,{error}) => ({
        ...state,
        download:null,
        error
      })),
      on(ProjectsActions.noDownloads, (state) => ({
        ...state,
        download:null,
        error:null
      })),
      on(ProjectsActions.toggleValidationModal, (state,{canShow}) => ({
        ...state,
        showValidationModal:canShow,
        createdValidationRequest:null
      })),
      on(ProjectsActions.createValidationRequestSuccess, (state,{request}) => ({
        ...state,
        createdValidationRequest:request,
      })),
      on(ProjectsActions.createValidationRequestFailed, (state,{error}) => ({
        ...state,
        createdValidationRequest:null,
        error,
      })),
      on(ProjectsActions.changeValidationContactSuccess, (state,{contact}) => ({
        ...state,
        validationContact:contact,
      })),
      on(ProjectsActions.changeValidationContacttFailed, (state,{error}) => ({
        ...state,
        validationContact:null,
        error,
      })),

      on(ProjectsActions.toggleImportModal, (state,{canShow}) => ({
        ...state,
        showImportProject:canShow,
        importedProject:null,
        error:null,
      })),
      on(ProjectsActions.importProjectSuccess, (state,{project}) => ({
        ...state,
        importedProject:project,
        error:null,
      })),
      on(ProjectsActions.importProjectFailed, (state,{error}) => ({
        ...state,
        importedProject:null,
        error,
      })),
      on(ProjectsActions.toggleShareModal, (state,{canShow}) => ({
        ...state,
        showShareModal:canShow,
        sharedProject:null,
        error:null,
      })),
      on(ProjectsActions.toggleRefillModal, (state,{canShow}) => ({
        ...state,
        showRefillModal:canShow
      })),
      on(ProjectsActions.retrieveCustomerCompanySuccess, (state,{customerData}) => ({
        ...state,
        customerData,
      })),
      on(ProjectsActions.retrieveCustomerCompanyFailed, (state,{error}) => ({
        ...state,
        customerData:null,
        error
      }))
);

/*
this.flowCredits =this.company.reflowPoints
        this.validationHours = this.company.validations ;
        this.engineerhours = this.company.engineeringHours ;


*/