<div class="reflow-form">
    <div class="fixed-form-header">
      <div class="header-content">
        <label class="form-title">Add Fuel Option :</label>
        <em class="fa fa-times form-close" (click)="hideModal()"></em>
      </div>
    </div>
    <div class="form-container">
      <form [formGroup]="fuelOptionToCreate">
        <label class="section-title">FUEL OPTION INFO</label>
        
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="title"
              required
            />
            <label class="float-label">Fuel Title</label>
            <small *ngIf="submitted && fuelOptionToCreate.get('title')?.errors?.required">
              *Fuel title is required
            </small>
          </div>
        </div>
        
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="number"
              formControlName="coefficient"
              required
            />
            <label class="float-label">Coefficient</label>
            <small *ngIf="submitted && fuelOptionToCreate.get('coefficient')?.errors?.required">
              *Coefficient is required
            </small>
          </div>
        </div>
        
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="number"
              formControlName="percentage"
              required
            />
            <label class="float-label">Percentage</label>
            <small *ngIf="submitted && fuelOptionToCreate.get('percentage')?.errors?.required">
              *Percentage is required
            </small>
          </div>
        </div>
        
        <div class="row">
          <div class="field">
            <input
              class="re-input"
              type="text"
              formControlName="scenarioCategoryId"
              required
            />
            <label class="float-label">Scenario Category ID</label>
            <small *ngIf="submitted && fuelOptionToCreate.get('scenarioCategoryId')?.errors?.required">
              *Scenario Category ID is required
            </small>
          </div>
        </div>
  
      </form>
    </div>
    <div class="fixed-form-footer">
      <div class="footer-content">
        <button class="btn-solid default" (click)="hideModal()">Close</button>
        <button class="btn-solid secondary" (click)="onSubmit()">Done</button>
      </div>
    </div>
  </div>
  