<ng-container *ngIf="isLoading==true">
    loading
</ng-container>
<ng-container *ngIf="isLoading==false">
    <div class="empty-state" *ngIf="(count$|async)==0">
        <div class="empty-state-block">
      
            <div class="empty-state-description-block" [ngStyle]="{'margin-top': selectedTab === 'archived' ? '-112px' : '-2%'}">
                <div class="loop-button">
                    <img src="../../../assets/danish-shipping-assets/black-search-icon.svg" alt="">
                </div>
              <div class="empty-state-txt-block">
                <label class="empty-state-title">
                    {{ selectedTab === 'active' ? 'No active vessels' : 'No archived vessels' }}
                  </label>
                  <p class="empty-state-description">
                    {{ selectedTab === 'active' 
                      ? 'No vessels are currently active. Click "Add Vessel" to create one.' 
                      : 'No vessels are currently archived.' }}
                  </p>
              </div>
              <div class="empty-state-add-option" *ngIf="selectedTab === 'active'" (click)="toggleDrawer()">
                <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
                <label for="">Add vessel</label>
              </div>
            </div>
        </div>
    </div>
    <div class="vessel-list" *ngIf="(count$|async)>0">
        <div class="list-item-container"  *ngFor="let vessel of vesselsList">
            <div class="vessel-details">
                <div class="title-content">
                    <label class="head-title">{{vessel.vesselName}}</label>
                </div>
                <div class="details-block">
                    <label class="details-txt">IMO: </label>
                    <label class="details-sub-txt">{{vessel.imo}}</label>
                </div>
                <div class="details-block">
                    <label class="details-txt">Lifetime: </label>
                    <label class="details-sub-txt">{{vessel.lifeTime}} years</label>
                </div>
        
                <div class="lifecycle-title-block">
                    <label class="lifecycle-title">Lifecycle Screening: {{vessel.totalEmission}}</label>
                    <label class="lifecycle-value">tCO2eq</label>
                </div>
        
                <div class="lab-link-container">
                    <label for="link-lab" class="link-lab">See Lifecycle profile</label>
                    <img src="../../../../../assets/danish-shipping-assets/drop-arrow-icon.svg" alt="Ship Image" class="drop-arrow-icon">
                </div>
            </div>
            <div class="vessel-buttons-container" *ngIf="activeTab=='active'">
                <div class="calculation-buttons-block">
                    <div class="calculation-buttons-item">
                            <div class="status-block">
                                <label for="" class="status-title">Completed</label>
                                <label for="" class="status-value">({{vessel.customCalculationsDraftCount}})</label>
                            </div>
                            <img src="../../../../../assets/danish-shipping-assets/arrow-obliq-icon.svg" alt="" class="">
                    </div>
                    <div class="calculation-buttons-item">
                            <div class="status-block">
                                <label for="" class="status-title">Drafts</label>
                                <label for="" class="status-value">({{vessel.customCalculationsCompletedCount}})</label>
                            </div>
                            <img src="../../../../../assets/danish-shipping-assets/arrow-obliq-icon.svg" alt="" class="">
                    </div>
                    <div class="calculation-buttons-item">
                            <div class="status-block">
                                <label for="" class="status-title">Archived</label>
                                <label for="" class="status-value">({{vessel.customCalculationsArchivedCount}})</label>
                            </div>
                            <img src="../../../../../assets/danish-shipping-assets/arrow-obliq-icon.svg" alt="" class="">
                    </div>
                    
                </div>
            </div>
        
            <div class="archived-container">
                <button class="archived-btn" (click)="archiveVessel(vessel)" *ngIf="activeTab=='active'"> 
                    <img src="../../../../../assets/danish-shipping-assets/archive-box-icon.svg" alt="Ship Image" class=" ">
                </button>
                <div class="calculate-btn" (click)="navigateToEmissionNavigator(vessel)" *ngIf="activeTab=='active'">
                    <img src="../../../../../assets/danish-shipping-assets/plus-icon.svg" alt="" class="">
                    <label for="">Calculate</label>
                </div>
                <button class="restore-btn" (click)="restoreVessel(vessel)" *ngIf="activeTab!='active'"> 
                    <img src="../../../../../assets/danish-shipping-assets/restore.svg" alt="Ship Image" class=" ">
                    <label for=""> Restore</label>
                </button>
        
            </div>
        </div>
    </div>
</ng-container>