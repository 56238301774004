import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user.model";
import { ToastrService } from "ngx-toastr";
import { UserState } from "../../../store/reducers/user.reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectCurrentUser } from "../../../store/selectors/user.selectors";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {

  public data: string[]=["","Light Calculator","Gaia Project","Shipping","Components management","Manage users"];
  
  currentUser$:Observable<any>;
  canAddSuperAdmin:boolean=false;
  submitted = false;
  userToCreate: FormGroup;
  
  @Output() toogleModal = new EventEmitter();
  @Input() public showForm: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private store: Store<{ user: UserState }>
  ) {
    this.currentUser$=store.select(selectCurrentUser);
    this.currentUser$.subscribe(ans=>{
      this.canAddSuperAdmin=ans.role=='Super Admin';
    })
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.userToCreate = this.formBuilder.group({
        email: ["", Validators.required],
        password: [""],
        role: ["", Validators.required],
        lastLogin: new Date(),
        acceptedTerms: false,
        profilePicture: "",
        fullName: [""],
        title: [""],
        department: [""],
        phoneNumber: [""],
        timer: [0],
        permission: [""],
        permissionDate: new Date().toISOString().split('T')[0],
        companyId: Number(params["id"]),
        access: this.formBuilder.array([]),
      });
    });
  }
  get frm() {
    return this.userToCreate.controls;
  }

  addUser() {
    this.submitted = true;
    if (this.userToCreate.invalid){
      return;
    }
    const user: User = new User(this.userToCreate.value);
    this.userService.addCompanyUser(user).subscribe((res) => {
      this.toastr.success("User", "User informations saved succefuly!");
      this.hideModal();
    });
  }

  public hideModal() {
    this.showForm = false;
    this.toogleModal.emit(this.showForm);
  }

  public changePermission(value) {
    this.userToCreate.controls["permission"].setValue(value);
  }

  onChange(accessToAdd) {
    this.userToCreate.get("access").value.push({access:accessToAdd});
    const index = this.data.indexOf(accessToAdd);
    this.data.splice(index,1); 
  }

}
