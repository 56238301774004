<div class="reflow-form">
  <form [formGroup]="userToCreate" autocomplete="off">
    <div class="fixed-form-header">
      <div class="header-content">
        <label class="form-title">Add user</label>
        <em class="fa fa-times form-close" (click)="hideModal()"></em>
      </div>
    </div>
    <div class="form-container">
      <div class="row" style="padding-top: 20px;">
        <div class="field">
          <input class="re-input" type="email" formControlName="email" placeholder="Email" />
          <label class="float-label">Email</label>
          <small *ngIf="submitted && frm.email.errors?.required">*email is required</small>
        </div>
      </div>
      
      <div class="row">
        <div class="field">
          <select class="re-input" formControlName="role">
            <option disabled selected value>Role </option>
            <option *ngIf="canAddSuperAdmin==true">Super Admin</option>
            <option>Master Admin</option>
            <option>User</option>
          </select>
          <small *ngIf="submitted && frm.role.errors?.required">*role is required</small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="fullName" required />
          <label class="float-label">Full name</label>
          <small *ngIf="submitted && frm.fullName.errors?.required">*name is required</small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="title" required />
          <label class="float-label">Title</label>
          <small *ngIf="submitted && frm.title.errors?.required">*title is required</small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="department" required />
          <label class="float-label">Department </label>
          <small *ngIf="submitted && frm.department.errors?.required">*department is required</small>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <input class="re-input" type="text" formControlName="phoneNumber" required />
          <label class="float-label">Mobile number</label>
          <small *ngIf="submitted && frm.phoneNumber.errors?.required">*mobile is required</small>
        </div>
      </div>

      <div class="row">

        <div class="field">
          <label class="label">Permission type</label><br>
          <div class="in-radio">
            <input type="radio" id="free" name="gender" value="Free"
              (change)="changePermission('Free')">
            <label for="free"> Free</label><br>
            <input type="radio" id="premium" name="gender" value="Premium"
              (change)="changePermission('Premium')">
            <label for="premium"> Premium</label><br>
          </div>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="field">
          <input class="re-input" type="date" formControlName="permissionDate" required />
          <label class="float-label">Permission duration</label>
          <small *ngIf="submitted && frm.permissionDate.errors?.required">*Permission duration is required</small>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="field">
          <select class="re-input" (change)="onChange($event.target.value)">
            <option disabled selected value>Accessible modules </option>
            <option *ngFor="let access of data">{{access}}</option>
          </select>
          <small *ngIf="submitted && frm.role.errors?.required">*acess is required</small>
        </div>
        <ul class="tags">
          <li *ngFor="let item of this.userToCreate.get('access').value"><a href="#" class="tag">{{item.access}}</a></li>
        </ul>
      </div>
    </div>

    <div class="fixed-form-footer">
      <div class="footer-content">
        <button class="btn-solid default">Back</button>
        <button class="btn-solid secondary" (click)="addUser()">Add</button>
      </div>
    </div>
  </form>
</div>