import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { AuthService } from '@auth0/auth0-angular';
import { UserState } from "../../store/reducers/user.reducers";
import { Store } from "@ngrx/store";
import { UserActions } from "../../store/actions/user.actions";
import { Observable } from "rxjs";
import { selectCurrentUser, selectUserInfo } from "../../store/selectors/user.selectors";

@Component({
  selector: "app-sp-side-menu",
  templateUrl: "./sp-side-menu.component.html",
  styleUrls: ["./sp-side-menu.component.scss"],
})
export class SpSideMenuComponent implements OnInit {
  public currentUser: any;
  public currentUserAccess: any;
  public showResetView: boolean = false;
  public showImageView: boolean = false;
  public userRole:any="";
  public role:any="";
  public shippingAccess:boolean=false;
  currentUser$:Observable<any>;
  currentUserInfo$:Observable<any>;
  constructor(
    private userService: UserService,
    private router: Router,
    public auth: AuthService,
    private store: Store<{ user: UserState }>
  ) {
    this.currentUser$=store.select(selectCurrentUser);
    this.currentUserInfo$=store.select(selectUserInfo);
    this.currentUser$.subscribe(ans=>{
      this.shippingAccess=ans.access.filter(x=>x=="Shipping").length>0?true:false;
      this.currentUserAccess = ans;
      this.role=ans.role;
    })
    this.currentUserInfo$.subscribe(ans=>{
      this.currentUser = ans;
      if (this.currentUser.profilePicture == "") {
        this.currentUser.profilePicture = "../../../assets/loginbg.jpg";
      }
    })
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem("user_role");
  }

  public logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.auth.logout({ returnTo: `${window.location.origin}/login` });
    localStorage.clear();
  }

  public navigateHome(): void {
    let companyId: number = Number(localStorage.getItem("company_id"));
    this.router.navigate(["/homeServiceProvider", companyId]);
  }

  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
    this.showImageView = false;
  }

  public showImageModal(isShow: boolean) {
    this.showImageView = isShow;
    this.showResetView = false;
  }
  public chatStarted=false;
  public toggleChat(event){
    this.chatStarted=event
  }
}
