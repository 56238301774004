import { Component, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ScreeningService } from 'src/app/services/screening.service';
import { ScreeningResult } from '../../../models/dtos.model';
import { Screening } from '../../../models/screening.model';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { CompanyService } from 'src/app/services/company.service';
import { CompareService } from 'src/app/services/compare.service';

@Component({
  selector: 'app-compare-function',
  templateUrl: './compare-function.component.html',
  styleUrls: ['./compare-function.component.scss']
})
export class CompareFunctionComponent implements OnInit {
 NgModdel
  public mathPipe = Math;
  public projects;
  public showComparison=false;
  public noDataToShow=false;
  public showload=true;
  public screeningResults:any []=[];
  public comparisonResults:any;
  public specifications=['Project Name:','Product Name:','Product Type:','Weight:','Lifespan:']
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:false,
           scales: {
            xAxes: [{
                gridLines: {
                  drawOnChartArea: false,
                  color:'#DDDDDD',
                  zeroLineColor:'#DDDDDD',
                  offsetGridLines:false
                },
                ticks: {
                  beginAtZero: true,
                  fontFamily:'Nunito Sans',
                  stepSize: 1300,
                  callback: function(label:any, index, labels) {
                    if (/\s/.test(label)) {
                      return label.split(" ");
                    }else{
                      return label;
                    }              
                  }
                }
            }],
            yAxes: [{
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                callback: function(label:any, index, labels) {
                  return "";
                },
                stepSize: 1,
                beginAtZero: true
              }
          }]
        },
        tooltips: {
          titleFontFamily:'Nunito Sans',
          callbacks: {
              label: function(tooltipItems, data) {
                  return  Number(tooltipItems.xLabel).toFixed(2) + ' kg CO2 eq';
              }
          }
        }
  };
  public lifeCycleChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:false,
           scales: {
            xAxes: [{
                stacked:false,
                gridLines: {
                  drawOnChartArea: false,
                  color:'#DDDDDD',
                  zeroLineColor:'#DDDDDD'
                },
                ticks: {
                  beginAtZero: true,
                  fontFamily:'Nunito Sans',
                  stepSize: 1300,
                  callback: function(label:any, index, labels) {
                    if (/\s/.test(label)) {
                      return label.split(" ");
                    }else{
                      return label;
                    }              
                  }
                }
            }],
            yAxes: [{
              gridLines: {
                drawOnChartArea: false,
                color:'#DDDDDD',
                zeroLineColor:'#DDDDDD'
              },
              stacked:false,
              ticks: {
                stepSize: 1000000,
                beginAtZero: true,
                
              }
          }]
        },
        tooltips: {
          titleFontFamily:'Nunito Sans',
          callbacks: {
              label: function(tooltipItems, data) {
                  return  Number(tooltipItems.yLabel).toFixed(2) + ' kg CO2 eq';
              }
          }
        }
  };
  public barChartType: ChartType = 'horizontalBar';
  public footPrintLabels: Label[] ;
  public footPrintData: ChartDataSets[]=[];

  public lifeCycleChartType: ChartType = 'bar';
  public lifeCycleLabels: string[] = ['Manufacturing', 'Distribution', 'Usage', 'Maintenance', 'Remanufacturing', 'Refurbishment', 'Reconditioning','Recycling','Disposal'];
  public lifeCycleData: any[]=[];

  public chartColors=['#AEAEAE', '#123B68', '#83B9B7'];
  public chartColorsCycle: Array<any> = [
    { 
      backgroundColor: ['#AEAEAE', '#123B68', '#83B9B7']
    }
  ];
  public barChartLegend = false;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend:{
      display:false,
      position:'bottom',
      align:'start',
      fullWidth:false,
      labels:{
        boxWidth:15,
      }
    },
    tooltips: {
      titleFontFamily:'Nunito Sans',
      callbacks: {
          label: function(tooltipItems, data) {
              return  Number(data.datasets[0].data[tooltipItems.index]).toFixed(2) + ' kg CO2 eq';
          }
      }
    }
  };
  public pieChartLabels1: Label[] = [];
  public pieChartLabels2: Label[] = [];
  public pieChartLabels3: Label[] = [];
  public pieChartData1:any[] = [];
  public pieChartData2:any[] = [];
  public pieChartData3:any[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Array<any> = [
    { 
      backgroundColor: ['#2DB6F5', '#1A6C92', '#98D6D4','#ACEEFF','#123B68','#CCCCCC']
    }
  ];
  public barChartDataScopes:  ChartDataSets[] =[];
  constructor(
    public screeningService: ScreeningService,
    public compareservice: CompareService
  ) { }

  ngOnInit(): void {
    this.compareservice.getCompareResult(JSON.parse(localStorage.getItem('compare'))).subscribe(res=>{
      this.screeningResults =res.compareItems;
      this.comparisonResults = res;
      this.loading();
      this.footPrintLabels=res.footPrintLabels;
      for (let index = 0; index < res.compareItems.length; index++) {
        this.barChartLabelScopes.push(this.comparisonResults?.compareItems[index]?.productName);
      }
      this.barChartLabelsScopeDistribution = this.comparisonResults.scopeDistributionLifeCycles;
      this.pieChartData1=res.compareItems[0].parameterImpactsChart.map(x=>x.data);
      this.pieChartData2=res.compareItems[1].parameterImpactsChart.map(x=>x.data);
      this.pieChartData3=res.compareItems[2]?.parameterImpactsChart.map(x=>x.data);
      this.pieChartLabels1=res.compareItems[0].parameterImpactsChart.map(x=>x.label);
      this.pieChartLabels2=res.compareItems[1].parameterImpactsChart.map(x=>x.label);
      this.pieChartLabels3=res.compareItems[2]?.parameterImpactsChart.map(x=>x.label);
      this.footPrintData=[
        { data: res.footPrintdata, barThickness:12 }, 
      ];
      for (let index = 0; index < res.compareItems.length; index++) {
        setTimeout(() => {
          this.lifeCycleData.push( {data: res.compareItems[index].lifeCycleImpactsChart, label: res.compareItems[index].productName, stack: index+'', barThickness:12, backgroundColor:this.chartColors[index], hoverBackgroundColor:this.chartColors[index],categoryPercentage: 0.5, barPercentage: 0.5,  });
        },
          1000);
      }
      
        for (let index = 2; index >=0; index--) {
          setTimeout(() => {
            this.barChartDataScopes.push({ data: this.comparisonResults.scopeImpactContributions[index].scopeProductImpacts.map(x=>x.scopeImpact), label: 'Scope '+Number(index+1), stack: 'a' })
          },
          1000);
        }
      

        for (let index = 0; index < res.compareItems.length; index++) {
          setTimeout(() => {
            this.scope1BarChartDataScopeDistribution.push({data: this.comparisonResults.scopeLifeCycleDistributions[0].productScopesImpacts[index], label: 'Company A'}); 
            this.scope2BarChartDataScopeDistribution.push({data: this.comparisonResults.scopeLifeCycleDistributions[1].productScopesImpacts[index], label: 'Company A'}); 
            this.scope3BarChartDataScopeDistribution.push({data: this.comparisonResults.scopeLifeCycleDistributions[2].productScopesImpacts[index], label: 'Company A'}); 
          },
          1000);
        }  
      
      this.lifeCycleLabels=res.lifeCycleLabels;
    });
  }

  disp(todisp){
    
  }

  counter(i: number) {
    return new Array(i);
  }

  public downloadProof():void{
    this.compareservice.getProofOfCompare(JSON.parse(localStorage.getItem('compare')),"testCompare").subscribe(result=>{
      this.screeningService.DownloadFile(result, "testCompare"+".pdf", 'application/pdf')
    });
  }

  public loading(){
    setTimeout(() => this.showload=false, 4000);
  }
  //to be deleted when ui wired with backend
  public sampleDataProduct1=[20,40,10,25,5]
  public sampleDataProduct2=[10,30,20,35,5]
  public sampleDataProduct3=[4,18,20,40,18]
  public sampleDataProductColors=["#123B68","#6487A3","#98D6D4","#6E6E6E","#DDDDDD"]

  public barChartOptionScopes: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
            xAxes: [{
                stacked: true,
                ticks: {
                  stepSize:400000
                },
                gridLines: {
                  drawOnChartArea: false,
                  color:'#DDDDDD',
                  zeroLineColor:'#DDDDDD'
                },
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                  stepSize:400000
                },
                gridLines: {
                  drawOnChartArea: false,
                  color:'#DDDDDD',
                  zeroLineColor:'#DDDDDD'
                },
            }]
        }
  };


  public barChartLabelScopes:Label[]=[] ;
  public barChartTypeScopes: string = 'bar';
  public barChartLegendScopes: boolean = false;
  public Scopescolors = [
    { backgroundColor:"#123B68" },
    { backgroundColor:"#6487A3" },
    { backgroundColor:"#98D6D4" },
    ];

    public barChartOptionsScopeDistribution: any = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        xAxes: [{
            gridLines: {
              drawOnChartArea: false,
              color:'#DDDDDD',
              zeroLineColor:'#DDDDDD'
            },
        }]
      }
    };
    public barChartLabelsScopeDistribution: string[]=[];
    public barChartTypeScopeDistribution: string = 'bar';
    public barChartLegendScopeDistribution: boolean = false;
  
    public scope1BarChartDataScopeDistribution: any[]=[];
    public scope2BarChartDataScopeDistribution: any[]=[];
    public scope3BarChartDataScopeDistribution: any[]=[];
    public ScopeDistributioncolors = [
      { backgroundColor:"#AEAEAE" },
      { backgroundColor:"#123B68" },
      { backgroundColor:"#98D6D4" },
      ];
}
