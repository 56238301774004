import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompaniesService } from '../../../services/yardscore-services/companies.service';

@Component({
  selector: 'app-ysa-delete-company',
  templateUrl: './ysa-delete-company.component.html',
  styleUrls: ['./ysa-delete-company.component.scss']
})
export class YsaDeleteCompanyComponent implements OnInit {

  @Input() currentCompany:any=null;
  @Output() closeDeleteModal:any= new EventEmitter();

  public deletePhase=1;

  constructor(
    private companiesService:CompaniesService
  ) { }

  ngOnInit(): void {
  }

  public delete(){
    this.companiesService.deleteCompany(this.currentCompany?.id).subscribe(ans=>{
      this.deletePhase=2;
    },err=>{
      this.closeDeleteModal.emit(false);
    })
  }

  public backToCompaniesList(){
    this.closeDeleteModal.emit(true);
  }

  public cancel(){
    this.closeDeleteModal.emit(false);
  }

}
