import { Component, Output,Input, OnInit, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CurrencyPipe } from '@angular/common';
import { ProjectsState } from '../store/reducers/project.reducer';
import { Store } from '@ngrx/store';
import { ProjectsActions } from '../store/actions/project.actions';
declare function reload_cdn():any;
@Component({
  selector: 'app-refill-modal',
  templateUrl: './refill-modal.component.html',
  styleUrls: ['./refill-modal.component.scss']
})
export class RefillModalComponent implements OnInit {
  @Input() isAdmin:boolean=false;
  public companyId: number;
  public credit_to_refill: number;
  public selected = 'dkk';
  public selectedCredit = '';
  public selectedTotal : any = '0';
  public dkk5 :any =  2500;
  public dkk10 :any =  4500; 
  public dkk25 :any =  10000;
  public usd5 :any;
  public usd10 :any; 
  public usd25 :any;
  public eur5 :any;
  public eur10 :any; 
  public eur25 :any;
  public price5:any = this.cp.transform(this.dkk5,'DKK','','1.2-2') + ' DKK';
  public price10:any = this.cp.transform(this.dkk10,'DKK','','1.2-2') + ' DKK';
  public price25:any = this.cp.transform(this.dkk25,'DKK','','1.2-2') + ' DKK';
  public submessage5 = '500 DKK cost/credits';
  public submessage10 = 'Savings 500 DKK';
  public submessage25 = 'Savings 2,500 DKK';
  public selectedC ="dkk";
  public selectedElement = '0';
  public data;
  public response; 
  public creditCharge="nocharge"; 
  public selectedOffer=0;
   
  public url ='https://v6.exchangerate-api.com/v6/1b11d631872c020505cb30d1/latest/DKK'
  constructor(private http: HttpClient,private cp: CurrencyPipe,private projectsStore: Store<{ projects: ProjectsState }>,) { reload_cdn(); }
  ngOnInit(): void {
    reload_cdn();
    //this.isAdmin=false;
    this.http.get(this.url).subscribe((res)=>{
    this.response = res;
    this.data =  this.response.conversion_rates;

    this.usd5 = this.dkk5 * this.data.USD;
    this.usd10 = this.dkk10 * this.data.USD;
    this.usd25 = this.dkk25 * this.data.USD;

    this.eur5 = this.dkk5 * this.data.EUR;
    this.eur10 = this.dkk10 * this.data.EUR;
    this.eur25 = this.dkk25 * this.data.EUR;  
    })
  }
  cancel() {
    this.projectsStore.dispatch(ProjectsActions.toggleRefillModal({canShow:false}));
  }
  public selectCurrency(element){  
    
   
    if(element == 'eur')
    {       
      this.selectedC ='eur'; 
      this.price5 = this.cp.transform(this.eur5,'EUR','','1.2-2') + ' EUR';
      this.price10 = this.cp.transform(this.eur10,'EUR','','1.2-2')+ ' EUR';
      this.price25 = this.cp.transform(this.eur25,'EUR','','1.2-2')+ ' EUR';
      this.submessage5 = '70 EUR cost/credit';
      this.submessage10 = 'Savings 70 EUR';
      this.submessage25 = 'Savings 350 EUR';  
      if(this.selectedElement == '+5')
      {      
      this.selectedTotal = this.dkk5 *  this.data.EUR +" EUR";
      this.creditCharge = "credit5-EUR"
      } else if(this.selectedElement == '+10')
      {
        this.selectedTotal = this.dkk10 *  this.data.EUR +" EUR";
        this.creditCharge = "credit10-EUR"
      }
      else if(this.selectedElement == '+25')
      {
        this.selectedTotal = this.dkk25 *  this.data.EUR +" EUR";
        this.creditCharge = "credit25-EUR"
      }
      else
      {
        this.selectedTotal = 0;
        this.creditCharge = "nocharge"
      }
    }
    if(element == 'dkk')
    {      
      this.selectedC ='dkk';       
      this.price5 = this.dkk5 + ' DKK';
      this.price10 = this.dkk10+ ' DKK';
      this.price25 = this.dkk25+ ' DKK';
      this.submessage5 = '500 DKK cost/credits';
      this.submessage10 = 'Savings 500 DKK';
      this.submessage25 = 'Savings 2,500 DKK'; 
      if(this.selectedElement == '+5')
      {      
      this.selectedTotal = this.dkk5 +" DKK";
      this.creditCharge = "credit5-DKK"
      } else if(this.selectedElement == '+10')
      {
        this.selectedTotal = this.dkk10 +" DKK";
        this.creditCharge = "credit10-DKK"
      }
      else if(this.selectedElement == '+25')
      {
        this.selectedTotal = this.dkk25 +" DKK";
        this.creditCharge = "credit25-DKK"
      }
      else
      {
        this.selectedTotal = 0;
        this.creditCharge = "nocharge"
      }
      
    }
    if(element == 'usd')
    { 
      this.selectedC ='usd';
      this.price5 = this.cp.transform(this.usd5,'USD','','1.2-2') + ' USD';
      this.price10 = this.cp.transform(this.usd10,'USD','','1.2-2')+ ' USD';
      this.price25 = this.cp.transform(this.usd25,'USD','','1.2-2')+ ' USD';
      this.submessage5 = '80 USD cost/credit';
      this.submessage10 = 'Savings 80 USD';
      this.submessage25 = 'Savings 400 USD'; 
      if(this.selectedElement == '+5')
      {      
      this.selectedTotal = this.dkk5 *  this.data.USD +" USD";
      this.creditCharge = "credit5-USD"
      } else if(this.selectedElement == '+10')
      {
        this.selectedTotal = this.dkk10 *  this.data.USD +" USD";
        this.creditCharge = "credit10-USD"
      }
      else if(this.selectedElement == '+25')
      {
        this.selectedTotal = this.dkk25 *  this.data.USD +" USD";
        this.creditCharge = "credit25-USD"
      }else
      {
        this.selectedTotal = 0;
        this.creditCharge = "nocharge"
      }
     
    }
    if(this.selected == element){
      this.selected = '';
    }else{
      this.selected = element;       
    }    
        
  }

  public selectCredit(element){     
    if(this.selectedCredit == element){
      this.selectedCredit = '';      
      this.selectedElement ='0'
    }else{
      this.selectedCredit = element;
    }
    if(element == '+5'){
      this.selectedElement = '+5';
      this.selectedTotal = document.getElementById('text-price-5').innerHTML;
      if(this.selectedC == "dkk")
      {
        this.creditCharge = "credit5-DKK" 
      }
      if(this.selectedC == "eur")
      {
        this.creditCharge = "credit5-EUR" 
      }
      if(this.selectedC == "usd")
      {
        this.creditCharge = "credit5-USD" 
      }
    }
    if(element == '+10'){
      this.selectedElement = '+10';
      this.selectedTotal = document.getElementById('text-price-10').innerHTML;
      if(this.selectedC == "dkk")
      {
        this.creditCharge = "credit10-DKK" 
      }
      if(this.selectedC == "eur")
      {
        this.creditCharge = "credit10-EUR" 
      }
      if(this.selectedC == "usd")
      {
        this.creditCharge = "credit10-USD" 
      }
    }
    if(element == '+25'){
      this.selectedElement = '+25';
      this.selectedTotal = document.getElementById('text-price-25').innerHTML;
      if(this.selectedC == "dkk")
      {
        this.creditCharge = "credit25-DKK" 
      }
      if(this.selectedC == "eur")
      {
        this.creditCharge = "credit25-EUR" 
      }
      if(this.selectedC == "usd")
      {
        this.creditCharge = "credit25-USD" 
      }
    }
    if(this.selectedCredit == '')
    {      
      this.selectedTotal = '0';
      this.creditCharge = "nocharge" 
    }      
  }  
  public checkout()
  {
    localStorage.setItem('credit_to_refill', this.selectedElement.substring(1));
  }


}
