import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterViewChecked, Component, ElementRef, OnInit, Output, ViewChild,EventEmitter, Input } from '@angular/core';

import { Observable, timer } from 'rxjs';
import { ContactService } from 'src/app/services/contact.service';
import { UserService } from 'src/app/services/user.service';
import { UserState } from '../../store/reducers/user.reducers';
import { Store } from '@ngrx/store';
import { selectUserId } from '../../store/selectors/user.selectors';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.css']
})
export class CustomerContactComponent implements OnInit,AfterViewChecked  {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Output() toggleChat=new EventEmitter();
  @Input() chatStarted;
  public started=false;
  public messageToSend:string="";
  public messages:any[];
  public currentUser:any;
  currentUser$:Observable<any>;

  constructor(
    private contactService:ContactService, 
    private userService:UserService,
    private store: Store<{ user: UserState }>
  ) { 
    this.currentUser$=store.select(selectUserId);
    setInterval(()=> { 
      if(this.started){
        this.fetchMessages()
      }
     }, 5000); 
  }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user=>{
      this.userService.getUserById(Number(user.userId)).subscribe(res=>{
        this.currentUser=res;
      });
    });
    
  }
  ngAfterViewChecked() {        
    //this.scrollToBottom();        
  } 
  
  public fetchMessages():void{
    var thread = localStorage.getItem("thread");
    if(thread!=""){
      this.contactService.getMessages(thread).subscribe(res=>{
        this.messages=res;
        this.scrollToBottom();
      });
    }
  }
  
  public startChat(){
    this.started=true
  }
  public close(){
    this.started=false
    this.toggleChat.emit(false);
  }

  public submitMessage(id:any):void{
    var thread = localStorage.getItem("thread");
    if(this.messageToSend!=""){
      if(thread!="" && thread!=null){
        var msgDto = {text:this.messageToSend, thread_ts:thread};
        this.contactService.sendMessage(msgDto,id).subscribe(res=>{
          this.fetchMessages();
          this.messageToSend="";
          this.scrollToBottom();   
        });
      }
      else{
        var newMsgDto = {text:this.messageToSend,thread_ts:"",channel:""};
        this.contactService.sendMessage(newMsgDto,id).subscribe(res =>{
          this.messageToSend="";
          localStorage.setItem("thread",res.thread)
          this.scrollToBottom();   
        });
      }
    }
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }
}
