export function loadScenariosFromLocalStorage(): any {
    const savedScenarios = localStorage.getItem('scenarios');
    
    return savedScenarios ? JSON.parse(savedScenarios) : null;
  }
  
  /**
   * Save `scenarios` to localStorage.
   * @param scenarios The `scenarios` data to persist.
   */
  export function saveScenariosToLocalStorage(scenarios: any): void {
    localStorage.setItem('scenarios', JSON.stringify(scenarios));
  }


  export function loadVesselsFromLocalStorage(): any {
    const savedVessels = localStorage.getItem('vessels');
    
    return savedVessels ? JSON.parse(savedVessels) : null;
  }
  
  /**
   * Save `scenarios` to localStorage.
   * @param scenarios The `scenarios` data to persist.
   */
  export function saveVesselsToLocalStorage(vessels: any): void {
    localStorage.setItem('vessels', JSON.stringify(vessels));
  }