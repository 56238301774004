import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';

@Component({
  selector: 'app-overview-stats',
  templateUrl: './overview-stats.component.html',
  styleUrls: ['./overview-stats.component.scss']
})
export class OverviewStatsComponent implements OnInit {

  @Input() stats:any=null;
  @Input() count:any=null;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { }

  ngOnInit(): void {
    
  }

}
