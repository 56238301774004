<ng-container *ngIf="currentUser$ |async as currentUser">
    <button class="open-button border _chat-button" *ngIf="!started" (click)="startChat()">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYEr7h1HVarwv2AGGelAUc3jpi5coqAiQKH2WrOxOtyT5ayuk4iKMjusLPv7u-khVjxVE&usqp=CAU " class="siz" alt="">
        <span class="_hide">
            Contact us
        </span>
    </button>
    <div *ngIf="started||chatStarted" class="chat_support_window">
       
        <section class="msger">
            <header class="msger-header" (click)='close()'>
              <div class="msger-header-title">
                <em class="fas fa-comment-alt"></em> Reflow client support
              </div>
              <div class="msger-header-options">
                <span><em class="fa fa-window-minimize"></em></span>
              </div>
            </header>
            <main class="msger-chat" #scrollMe>
                <div class="msg right-msg" *ngIf="messages?.length!=0">
                    <div class="msg-bubble">
                        <div class="msg-info">
                        <div class="msg-info-name">Reflow Support</div>
                        </div>
                
                        <div class="msg-text">
                            "Hello {{currentUser.fullName}}!
                            Welcome to ReFlow's platform support.
                            The chat will be live Monday to Friday from 09.00 - 17.00.
                            Outside working hours response time may vary.
                            How may the ReFlow team help you today? "
                        </div>
                    </div>
                </div>
                <div *ngFor="let msg of messages" [class]="msg.user == 'you' ? 'msg right-msg' : 'msg left-msg'">
                    <div class="msg-bubble">
                        <div class="msg-info">
                        <div class="msg-info-name">{{msg.user}}</div>
                        </div>
                        <div class="msg-text">
                            {{msg.text}}
                        </div>
                    </div>
                </div>
            </main>
          
            <div class="msger-inputarea">
              <input type="text" class="msger-input" [(ngModel)]="messageToSend" placeholder="Enter your message...">
              <button type="submit" class="msger-send-btn" (click)="submitMessage(currentUser)">Send</button>
            </div>
          </section>
    </div>
</ng-container>