import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifeCycle } from 'src/app/models/life-cycle.model';
import { Screening, ScreeningLog } from 'src/app/models/screening.model';
import { LifeCycleServiceService } from 'src/app/services/life-cycle-service.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';
import { MaterialModalComponent } from './material-modal/material-modal.component';

@Component({
  selector: 'app-screening-inventory',
  templateUrl: './screening-inventory.component.html',
  styleUrls: ['./screening-inventory.component.scss']
})
export class ScreeningInventoryComponent implements OnInit {
  @Output() public close = new EventEmitter();

  public screeningId:number;
  public screening:Screening;
  public lifeCycleArray: FormArray;
  public lifeCycles:LifeCycle[];
  public activeCollapsible:number=0;
  public activeCollapsibleButton:number=null;
  public loading:boolean=true;
  public saveLoading:boolean=false;
  public selectedLifeCycle:string="";
  public screening_reflow_id;
  public lifespanModal = false;
  public _massUnit=['Kg','g','lb','mg','oz','ton','sh. ton','imp. ton']
  public _energy=['kWh','btu','kcal','J','KJ','MJ','GJ','MWh']
  public _distance=['Km','m','mile','nautical mile']
  public _volume=['m³','cm³','dm³','gal','ml','l']
  public tempConcept={}
  public disable=true;
  public showMessage = "Show less";
  public showMaterialMessage = false;
  public showTransportMessage = false;
  public showEnegeryUsageMessage= false;
  public showMaritimeComponentMessage= false;
  public showElectronicComponentMessage= false;
  public showSharedImportedMessage= false;
  public showWasetMessage= false;
  public showRecycledMessage= false;
  public answer = [];
  public border_class=""
  public selected_activity = "Specify activity";
  public show_specify = false;
  public click_activity = false;
  public showScopesActivity = false;
  public arrowType = "show_less.png";

  constructor(
      private activatedRoute:ActivatedRoute,
      private lifeCycleService:LifeCycleServiceService,
      private userService:UserService ,
      private screeningService: ScreeningService,
      private fb:FormBuilder,
      private modalService: NgbModal,
    ) { }

  ngOnInit(): void {
    this.lifeCycleArray = this.fb.array([]);
    this.activatedRoute.params.subscribe((params) => {
      this.screeningId= Number( params["screeningId"]);
      this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
        this.screening=res;
        this.showScopesActivity=res.includeScopes;
        this.disable=this.screening['validated'];
      });
      this.screeningService.updateState(this.screeningId,"screening-inventory").subscribe(res=>{
        this.screeningService.getInventoryById(this.screeningId).subscribe(inventory=>{
          this.lifeCycles=inventory;
          this.lifeCycles.forEach(lifeCycle => {
            if(lifeCycle.stage=="Distribution"){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                input: [lifeCycle.input],
                output: [lifeCycle.output],
                transports:this.fb.array(lifeCycle.transports),
              }));
            }
            else if(lifeCycle.stage=="Usage"){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                energies:this.fb.array(lifeCycle.energies),
                directEmissions:[""],
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else if(lifeCycle.stage=="Recycling"){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                //recycles:this.fb.array(lifeCycle.recycles),
                recycledMaterials:this.fb.array(lifeCycle?.recycledMaterials),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else if(lifeCycle.stage == "Remanufacturing" || lifeCycle.stage == "Refurbishment" || lifeCycle.stage == "Reconditioning" ){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
                sharedComponents:this.fb.array(lifeCycle?.sharedComponents),
                maritimeComponents:this.fb.array(lifeCycle?.maritimeComponents),
                electronicComponents:this.fb.array(lifeCycle?.electronicComponents),
                recycledMaterials:this.fb.array(lifeCycle?.recycledMaterials),
              }));
            }
            else if(lifeCycle.stage == "Disposal" ){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else if(lifeCycle.stage == "Manufacturing" ){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                sharedComponents:this.fb.array(lifeCycle?.sharedComponents),
                maritimeComponents:this.fb.array(lifeCycle?.maritimeComponents),
                electronicComponents:this.fb.array(lifeCycle?.electronicComponents),
                recycledMaterials:this.fb.array(lifeCycle?.recycledMaterials),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else if(lifeCycle.stage == "Maintenance" ){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                sharedComponents:this.fb.array(lifeCycle?.sharedComponents),
                maritimeComponents:this.fb.array(lifeCycle?.maritimeComponents),
                electronicComponents:this.fb.array(lifeCycle?.electronicComponents),
                recycledMaterials:this.fb.array(lifeCycle?.recycledMaterials),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else if(lifeCycle.stage == "Installation" ){
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                sharedComponents:this.fb.array(lifeCycle?.sharedComponents),
                maritimeComponents:this.fb.array(lifeCycle?.maritimeComponents),
                electronicComponents:this.fb.array(lifeCycle?.electronicComponents),
                recycledMaterials:this.fb.array(lifeCycle?.recycledMaterials),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
              }));
            }
            else{
              this.lifeCycleArray.push(this.fb.group({
                id:lifeCycle.id,
                stage: [lifeCycle.stage],
                activity: [lifeCycle.activity],
                lifeSpan:  [lifeCycle.lifeSpan],
                location: [lifeCycle.location, Validators.required],
                materials:this.fb.array(lifeCycle.materials),
                transports:this.fb.array(lifeCycle.transports),
                energies:this.fb.array(lifeCycle.energies),
                wastes:this.fb.array(lifeCycle.wastes),
                input: [lifeCycle.input],
                output: [lifeCycle.output],
                sharedComponents:this.fb.array(lifeCycle?.sharedComponents),
                maritimeComponents:this.fb.array(lifeCycle?.maritimeComponents),
                electronicComponents:this.fb.array(lifeCycle?.electronicComponents),
              }));
            }
          });
          this.loading=false;
        });
        this.userService.userReactions(Number(localStorage.getItem('user_id')), this.screeningId).subscribe(ans=>{
          ans.forEach(element => {
            if(this.stages.map(e => e.name).indexOf(element['tipSlug'])!=-1){
              this.stages[this.stages.map(e => e.name).indexOf(element['tipSlug'])]['feedback']=element['feedback']
            }else{
              this.stages[this.stages.map(e => e.name).indexOf(element['tipSlug'])]['feedback']=null
            }

          });


        })
      });
    });

    this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
    this.screening_reflow_id = res.reflowId;
    });

  }
  onClose(): void {
    this.lifespanModal = false;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  public closeLifeSpanModal(event) {
    this.lifespanModal = false;
    this.lifeCycleArray.controls.filter(x=>x.get("stage").value==this.selectedLifeCycle)[0].get("lifeSpan").setValue(Number(event));
  }

  public showLifeSpanModal(isShow: boolean, lifeCycle:string) {
    this.selectedLifeCycle=lifeCycle;
    this.lifespanModal = isShow;
  }

  public deleteMaterial(section:string,lifeCycleId):void{
    let answerLength = this.answer.length;
    if(this.disable)return;
    else if(section=="sharedComponents"){
      for (let i = 0; i < answerLength; i++) {
        let indexRemove = this.lifeCycleArray.controls.filter(x=>x.get("id").value == lifeCycleId)[0].get(section).value.indexOf(this.answer[0]['inventory']);
        this.lifeCycleArray.controls.filter(x=>x.get("id").value == lifeCycleId)[0].get(section).value.splice(indexRemove,1);
        this.answer.splice(0,1);
      }
    }
    else{
      for (let i = 0; i < answerLength; i++) {
          let indexRemove = this.lifeCycleArray.controls.filter(x=>x.get("id").value == lifeCycleId)[0].get(section).value.indexOf(this.answer[0]['inventory']);
          if(this.answer[0].inventory.lifeCycleId==lifeCycleId && this.answer[0]["section"].toUpperCase()==section.toUpperCase()){
            this.lifeCycleArray.controls.filter(x=>x.get("id").value == lifeCycleId)[0].get(section).value.splice(indexRemove,1);
            this.answer.splice(0,1);
          }
      }
    }

  }

  public openMaterialModal(lifeCycleId:number,group:string):void{
    let material = {
      lifeCycleId:lifeCycleId,
      name: "",
      unit: "",
      locationEmissionId:0,
      locationName:""
    }
    const modalRef = this.modalService.open(MaterialModalComponent, {size: 'xl' });
    modalRef.componentInstance.material = material;
    modalRef.componentInstance.title = group;
    modalRef.componentInstance.screeningId = this.screeningId;

    if(group=="Materials")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("materials").value.push(receivedEntry);
      });
    }
    if(group=="Recycled Materials")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("recycledMaterials").value.push(receivedEntry);
      });
    }
    else if(group=="Usage")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("energies").value.push(receivedEntry);
      });
    }
    else if(group=="Waste")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("wastes").value.push(receivedEntry);
      });
    }
    else if(group=="Distribution")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("transports").value.push(receivedEntry);
      });
    }
    else if(group=="Shared Component")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("sharedComponents").value.push(receivedEntry);
      });
    }
    else if(group=="MARITIME SYSTEMS & COMPONENTS")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("maritimeComponents").value.push(receivedEntry);
      });
    }
    else if(group=="Electronic components")
    {
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.lifeCycleArray.controls.filter(x=>x.get("id").value==lifeCycleId)[0].get("electronicComponents").value.push(receivedEntry);
      });
    }
  }

  public moveToNext(lifeCycle:any,stage:number):void{
    this.activeCollapsible=stage+1;
    let id :number = stage+1;
    let currentStage = document.getElementById("collapseInventory"+stage);
    let nextStage = document.getElementById("collapseInventory"+id);
    currentStage.className="collapse";
    if(nextStage!=null){
      nextStage.className+=" show";
    }
    this.screeningService.updateLifeCycle(lifeCycle.id,lifeCycle).subscribe(res=>{
      this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
        let screeningLog = {date:new Date(),description:"Update Screening Life cycle "+stage,screeningId:this.screeningId,userName:user.fullName };
        this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
        });
      });
    });
  }

  public next() {
    var itemsProcessed = 0;
    this.loading=true;
    this.lifeCycleArray.value.forEach(lifecycle => {
      this.screeningService.updateLifeCycle(lifecycle.id,lifecycle).subscribe(res=>{
        itemsProcessed++;
        if(itemsProcessed===this.lifeCycleArray.value.length){
          this.close.emit("next");
          this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
            let screeningLog = {date:new Date(),description:"Approve Screening Inventory",screeningId:this.screeningId,userName:user.fullName };
            this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
            });
          });
        }
      });
    });

  }

  public save() {
    var itemsProcessed = 0;
    this.loading=true;
    this.lifeCycleArray.value.forEach(lifecycle => {
      this.screeningService.updateLifeCycle(lifecycle.id,lifecycle).subscribe(res=>{
        itemsProcessed++;
        if(itemsProcessed===this.lifeCycleArray.value.length){
          this.close.emit("close");
        }
      });
    });
  }

  public previous() {
    var itemsProcessed = 0;
    this.loading=true;
    this.lifeCycleArray.value.forEach(lifecycle => {
      this.screeningService.updateLifeCycle(lifecycle.id,lifecycle).subscribe(res=>{
        itemsProcessed++;
        if(itemsProcessed===this.lifeCycleArray.value.length){
          this.close.emit("previous");
        }
      });
    });

  }

  public setActiveCollapsible(id){
    this.activeCollapsible!==id?this.activeCollapsible=id:this.activeCollapsible=null;
  }

  public findConcept(key,default_concept,cid){

    if(this._massUnit.indexOf(key)!=-1){
      this.tempConcept[default_concept+cid]=this._massUnit;
    }else
    if(this._energy.indexOf(key)!=-1){
      this.tempConcept[default_concept+cid]=this._energy;
    }else
    if(this._distance.indexOf(key)!=-1){
      this.tempConcept[default_concept+cid]=this._distance;
    }else
    if(this._volume.indexOf(key)!=-1){
      this.tempConcept[default_concept+cid]=this._volume;
    }

  }

  public conceptFromKey(key){
    if(key=='kg')return this._massUnit
    if(key=='kWh')return this._energy
    if(key=='km')return this._distance
    if(key=='m³')return this._volume
  }

  public selectUnit(groupName:any,material:any,unit:any):void{
    let updatedMaterial=material;
    updatedMaterial.unit=unit;
    this.lifeCycleArray.controls.filter(x=>x.get("id").value==material.lifeCycleId)[0].get(groupName).value.filter(x=>x==material)[0]=updatedMaterial;
  }

  public stages=[
    {
      'name':'Manufacturing',
      'description':"Please list all inputs of materials and energy used to produce one unit of product, as well as any outputs of the stage such as waste produced during the manufacturing process.",
      'tips':'Wastes generated during the manufacturing phase should be included here rather than in the Disposal stage.',
      'feedback':null
    },
    {
      'name':'Usage',
      'description':"Please detail energy consumption and direct emissions related to the use of the product.",
      'tips':'',
      'feedback':null
    },{
      'name':'energy usage',
      'tips':'Datasets in the databases count both embodied emissions originating from the upstream activities as well as direct emissions from fuel combustion.',
      'feedback':null
    },{
      'name':'Distribution',
      'description':"Please list all means of logistics used to transport one unit of product.",
      'tips':'This stage concerns exclusively the distribution of the product itself rather than the transportation of process inputs of other life cycle stages (e.g., materials).',
      'feedback':null
    },
    {
      'name':'Remanufacturing',
      'description':"Please list all inputs such as materials and energy, used to produce one unit of product, as well as outputs such as wastes produced during the remanufacturing process.",
      'tips':'Wastes generated during the remanufacturing phase should be included here rather than in the Disposal or Manufacturing stages.',
      'feedback':null
    },
    {
      'name':'Disposal',
      'description':"Please list all inputs of materials and energy used for the disposal of the materials, as well as the outputs, such as the wastes of the product or part of the product that is meant to be disposed of.",
      'tips':'This stage concerns exclusively the disposal of the product or parts of the product rather than the disposal of wastes produced in other stages (e.g., Manufacturing, Maintenance and etc.)',
      'feedback':null
    },
    {
      'name':'Refurbishment',
      'description':"Please list all inputs such as materials and energy used to produce one unit of product, as well as outputs such as wastes produced during the refurbishment process.",
      'tips':'Waste generated during the refurbishment phase should be included here rather than in the Disposal or Manufacturing stages.',
      'feedback':null
    },
    {
      'name':'Reconditioning',
      'description':"Please list all inputs such as materials and energy used to recondition one unit of product, as well as any outputs such as wastes produced during the reconditioning process.",
      'tips':'Wastes generated during the reconditioning phase should be included here rather than in the Disposal or Manufacturing stages.',
      'feedback':null
    },
    {
      'name':'Recycling',
      'description':"Please list all inputs of materials and energy used for the recycling process, as well as outputs such as the wastes produced during the recycling process and the recycled materials that are meant to be recycled.",
      'tips':'Recyclable waste generated from other stages (e.g., manufacturing) should be included here under the output from the life cycle stage.',
      'feedback':null
    },
    {
      'name':'Maintenance',
      'description':"Please list all inputs into the system such as materials and energy used to maintain the product's performance and ensure its lifespan. As well as any outputs such as waste generated during the maintenance process.",
      'tips':'',
      'feedback':null
    },
    {
      'name':'Installation',
      'description':"Please list all inputs into the system such as materials and energy used to maintain the product's performance and ensure its lifespan. As well as any outputs such as waste generated during the Installation process.",
      'tips':'',
      'feedback':null
    },
  ]

  public getStage(name){
    return this.stages.filter(x=>x.name==name)[0];
  }

  public react(reaction,slug){
    this.userService.reactOnTips({
      projectId : this.screeningId,
      tipSlug : slug,
      feedback : reaction,
      userId : Number(localStorage.getItem('user_id'))
    }).subscribe(ans=>{
      this.stages[this.stages.map(e=>e.name).indexOf(slug)]['feedback']=reaction;
    })
  }
   public showMoreMessage(){
    if (this.showMessage == "Show more")
    {
      this.showMessage = "Show less"
      this.arrowType = "show_less.png"
    }else{
      this.showMessage = "Show more"
      this.arrowType = "arrows-expand.png"
    }
   }

   selectAnswer(selectedIndex,category, event,section) {
      var index = this.answer.map(function(e) { return e.id; }).indexOf(event.target.value);
      if (event.target.checked) {
          this.answer.push({id: event.target.value, inventory : category, section:section});
      }
      else{
        if (selectedIndex !== -1) {
          this.answer.splice(index, 1);
        }
      }
    }

    singleclik(){
      this.border_class = "grey-border";
    }

    selectActivity(activity: string,i:number)
    {
      this.border_class = "grey-border";
      this.lifeCycleArray.controls[i].get("activity").setValue(activity);
      if((activity =="Internal Activity") || (activity=="External Activity") )
      {
       this.border_class = "green-border";
       this.show_specify = true;
      }else{
        this.show_specify = false;
        this.border_class = "grey-border";
      }
    }
}
