import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService:HelperService
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public sendMessage(messageDto:any,id:any): any {
    return this.http.post<any>(
      `${this.environmentUrl}/api/messages/send/${id}`,
      messageDto,
     this.helperService.headers()
    );
  }

  public getMessages(thread:string): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/messages/thread/`+thread,
      this.helperService.headers()
    );
  }
}
