<div class="scenario-container">
  <!-- Navigation Tabs -->
  <div class="tabs">
    <div class="tab-container" >
      <div class="tab" [ngClass]="{'active': activeTabIndex === 0}">
        <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 0}">
          <img *ngIf="activeTabIndex > 0" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
          <span *ngIf="activeTabIndex === 0">
            <span class="circle"></span>
          </span>
          <span *ngIf="activeTabIndex !== 0 && activeTabIndex <= 0">1</span>
        </span>
        <label>Baseline building scenario</label>
      </div>
    </div>
    <div class="tab-container" >
      <div class="tab" [ngClass]="{'active': activeTabIndex === 1}">
        <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 1}">
          <img *ngIf="activeTabIndex > 1" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
          <span *ngIf="activeTabIndex === 1">
            <span class="circle"></span>
          </span>
          <span *ngIf="activeTabIndex !== 1 && activeTabIndex <= 1">2</span>
        </span>
        <label>Fuel Consumption and Type</label>
      </div>
    </div>
    <div class="tab-container" >
      <div class="tab" [ngClass]="{'active': activeTabIndex === 2}">
        <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 2}">
          <img *ngIf="activeTabIndex > 2" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
          <span *ngIf="activeTabIndex === 2">
            <span class="circle"></span>
          </span>
          <span *ngIf="activeTabIndex !== 2 && activeTabIndex <= 2">3</span>
        </span>
        <label>Recycling Method and Country</label>
      </div>
    </div>
  </div>

  <app-bls-step1 *ngIf="activeTabIndex === 0" [step1data]="step1data$|async"></app-bls-step1>

  <ng-container *ngIf="vesselLifecycleScenarios$|async as vesselLifecycleScenarios">
    <app-bls-step2 *ngIf="activeTabIndex === 1" [fuelConsumptionData]="vesselLifecycleScenarios[1]"></app-bls-step2>
    <app-bls-step3 *ngIf="activeTabIndex === 2" [recyclingMethodData]="vesselLifecycleScenarios[2]"></app-bls-step3>

  </ng-container>

  <!-- Buttons Section -->
  <div class="buttons-section">

    <div class="save-draft-btn" >
        <img src="../../../assets/danish-shipping-assets/arrow-left-dark-icon.svg" alt="" class="">
        <label for="">Save as draft</label>
    </div>
    <div class="next-btn" (click)="goToNextTab()">
        <label for="">{{ activeTabIndex === 2 ? 'Calculate' : 'Next' }}</label>
        <img  *ngIf="activeTabIndex !== 2"  src="../../../assets/danish-shipping-assets/arrow-right-light-icon.svg" alt="" class="">
    </div>
  </div>
  
 
</div>