<div class="modal">
  <div class="modal-content import-modal">
    <div class="modal-body">
      <!-- Left Column -->
      <div class="column left">
        <!-- Group Header -->
        <label class="h4-heading" >
          {{ scenario }} options
        </label>
    
        <!-- Sub-Groups Container -->
        <div class="sub-groups-container" *ngIf="materialOptions$|async as materialOptions">
          <!-- Sub-Groups -->
           <ng-container *ngFor="let subGroup of materialOptions" >
             <div class="row sub-group" (click)="showEndGroups(subGroup)">
               <em class="icon-folder-closed"></em>
               <label class="sub-group-name">{{ subGroup?.title }}</label>
             </div>
           </ng-container>
    
        </div>
  
      </div>
    
      <!-- Middle Column -->
      <div class="column mid">
        <!-- Search Box -->
        <div class="search-container">
          <div class="search-box">
            <em class="icon-search icon-search-left"></em>
            <input 
              type="text" 
              class="input-search" 
              [(ngModel)]="searchEGroup" 
              
              placeholder="Search groups..." />
          </div>
        </div>
    
        <!-- End Groups -->
        <label class="select" *ngIf="!canShowEndGroups">
          Please select a {{ title }} group...
        </label>
        <div class="end-groups-container" *ngIf="canShowEndGroups && !searchEGroup?.length">
          <ng-container *ngFor="let endGroup of selectedSubGroup?.scenarioOptions; let i = index">
            <div class="row end-group" (click)="selectEndGroup(endGroup)">
              <em class="icon-folder-closed"></em>
              <label class="end-group-name">{{ endGroup?.title }}</label>
            </div>
            
          </ng-container>
        </div>
    
        <!-- Search Results -->
        <div class="end-groups-container" *ngIf="canShowEndGroups && searchEGroup?.length">
          <ng-container *ngFor="let res of searchResults; let i = index">
            <div class="row end-group"  >
              <em class="icon-folder-closed"></em>
              <label 
                class="end-group-name" 
               >
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    
    
      <!-- Right Column -->
      <div class="column right">
        <!-- Close Button -->
        <div class="close">
          <em class="icon-close" (click)="close()"></em>
        </div>
        <div class="p-right" *ngIf="canShowDetails==true">
          <!-- Shared Details -->
            <div  class="details-container">
              <div class="row row-wrap details-header">
                <label class="shared-content">{{ selectedEndgroup?.title }}</label>
                <label class="shared-content-description">{{ selectedEndgroup?.description!=null&&selectedEndgroup?.description!=""?selectedEndgroup?.description:"no description available" }}</label>
              </div>

              <div class="quest-type-bloc" ngbDropdown *ngIf="selectedEndgroup?.country.length!=0">
                <label for="" class= "input-title">select Country</label>
                <div class="percentage-bloc" ngbDropdownToggle>
                  <span class="percentage-label">{{selectedCountry?.country==null||selectedCountry?.country==""?"select country":selectedCountry?.country}}</span>
                  <img src="../../../../assets/yardscore-assets/ys-i-dropdown.svg" alt="" class="percentage-icon">
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <ng-container *ngFor="let country of selectedEndgroup?.country">
                    <button ngbDropdownItem (click)="selectCountry(country)">{{country.country}}</button>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="buttons" >
              <button class="small-turq add" (click)="selectOption(selectedEndgroup)">
                <label>Add</label>
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

  