import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { Store } from '@ngrx/store';
import { VesselsOverviewActions } from '../../store/actions/vessels-overview.actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { selectCurrentVessel } from '../../store/selectors/vessels-overview.selectors';

@Component({
  selector: 'app-vessels-header',
  templateUrl: './vessels-header.component.html',
  styleUrls: ['./vessels-header.component.scss']
})
export class VesselsHeaderComponent implements OnChanges {
  routeBasedData:any={
    dashboard:{
      title:"Vessels",
      description:"This digital tool provides a standardized way to assess lifecycle emissions for various vessel types. It allows users to explore the impact of different fuels, technologies, and efficiency measures using reliable, pre-loaded data.",
      showMap:false,
      action:"add-vessel",
      mapElements:[]
    },
    navigator:{
      title:"Emission Navigator",
      description:"Choose different scenarios to calculate and compare the lifecycle emissions of the vessel.",
      showMap:true,
      action:"",
      mapElements:["Emission Navigator"]
    },
    baselinescenario:{
      title:"Vessel Lifecycle Screening",
      description:"Explore different vessel configurations to calculate lifecycle emissions.Choose different scenarios to calculate and compare the lifecycle emissions of the vessel.",
      showMap:true,
      action:"",
      mapElements:["Emission Navigator","Vessel Lifecycle Screening"]
    },
    baselinescenarioResult:{
      title:"Vessel Lifecycle Screening",
      description:"Explore different vessel configurations to calculate lifecycle emissions.Choose different scenarios to calculate and compare the lifecycle emissions of the vessel.",
      showMap:true,
      action:"download",
      mapElements:["Vessel Lifecycle Screening"]
    }
  }

  targetData:any=null;
  vesselId$:Observable<any>;
  private routerEventSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routerEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.firstChild?.data.subscribe(x=>{
          this.targetData=this.routeBasedData[x.title]
        })
      }
    });
    this.vesselId$=vesselsOverviewStore.select(selectCurrentVessel);
    
   }


  ngOnChanges(): void {
  }

  onAddVesselClick() {
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleVesselDrawer({canShow:true}))
  }

  downloadPdf() {
    this.vesselId$.subscribe(id=>{
      this.vesselsOverviewStore.dispatch(VesselsOverviewActions.downloadPdf({prefix:"lifecycle-screening",vesselId:id}))
    })
  }

  ngOnDestroy() {
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }
}
