
export const environment = {
  production: false,
  apiUrl: "https://api.development.re-flow.dk",
  aad_config:{
    api_id:'85f32023-8108-4367-800d-3363d1744a72',
    client_id:'d26db14b-d89d-40a3-a188-a2337cf05912',
    tenant_id:'5253849c-15bd-4518-a279-1acae980ca42'
  }
};

