import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yss-questionnaire-management',
  templateUrl: './yss-questionnaire-management.component.html',
  styleUrls: ['./yss-questionnaire-management.component.scss']
})
export class YssQuestionnaireManagementComponent implements OnInit {

  public selectedModule="Steel";

  constructor() { }

  ngOnInit(): void {
  }

  file:any;
  fileChanged(e) {
      this.file = e.target.files[0];
      
  }

}
