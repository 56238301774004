import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { VesselsOverviewState } from "../../store/reducers/vessels-overview.reducers";
import { VesselsOverviewActions } from "../../store/actions/vessels-overview.actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import { filter, take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import {
  selectCreatedVessel,
  selectError,
  selectNewVesselData,
} from "../../store/selectors/vessels-overview.selectors";

@Component({
  selector: "app-vessel-drawer",
  templateUrl: "./vessel-drawer.component.html",
  styleUrls: ["./vessel-drawer.component.scss"],
})
export class VesselDrawerComponent implements OnInit {
  @Input() companyId: any = null;

  error$: Observable<any>;
  newVesselData$: Observable<any>;
  createdVessel$: Observable<any>;

  selectedItem: string = "--Select Type--";
  vesselBuilderForm!: FormGroup;
  imageUrl: any = "../../../../../assets/danish-shipping-assets/ship.png";
  baseImageUrl: any = "../../../../../assets/danish-shipping-assets/ship.png";

  showIMOerror: boolean = false;
  showLoadingVesselData: boolean = false;
  showLoadingCreate: boolean = false;
  IMOErrorMessage: string = "";

  vesselTypes: any = [
    "Bulk Carrier",
    "Bunkering Tanker",
    "Chemical/Oil Products Tanker",
    "Container Ship",
    "Crane Ship",
    "Crew Boat",
    "Crude Oil Tanker",
    "Deck Cargo Ship",
    "Dredger",
    "FSO",
    "General Cargo Ship",
    "N/A",
    "Offshore Support Vessel",
    "Passenger (Cruise) Ship",
    "Tug",
    "Yacht",
  ];

  constructor(
    private vesselsOverviewStore: Store<{
      vesselsOverview: VesselsOverviewState;
    }>,
    private fb: FormBuilder,
    private toasterService: ToastrService
  ) {
    this.error$ = vesselsOverviewStore.select(selectError);
    this.newVesselData$ = vesselsOverviewStore.select(selectNewVesselData);
    this.createdVessel$ = vesselsOverviewStore.select(selectCreatedVessel);
  }

  ngOnInit(): void {
    this.vesselBuilderForm = this.fb.group({
      IMO: [""],
      LifeTime: [""],
      VesselName: [""],
      imageUrl: [""],
      VesselType: [""],
      VesselDWT: [""],
      VesselLength: [""],
      VesselBeam: [""],
      companyId: [this.companyId],
    });
  }

  toggleDrawer() {
    this.vesselsOverviewStore.dispatch(
      VesselsOverviewActions.toggleVesselDrawer({ canShow: false })
    );
  }

  selectItem(item: string) {
    this.vesselBuilderForm.get("VesselType")?.setValue(item);
    this.selectedItem = item;
  }

  importVesselData() {
    if (this.showLoadingVesselData == true) {
      return;
    }

    const imoValue = this.vesselBuilderForm.get("IMO")?.value;
    const validation = this.validateIMO(imoValue);

    if (!validation.isValid) {
      this.showIMOerror = true;
      this.IMOErrorMessage = validation.errorMessage;
      return;
    }

    this.showIMOerror = false;
    this.IMOErrorMessage = "";
    this.vesselsOverviewStore.dispatch(
      VesselsOverviewActions.importVesselData({
        imo: imoValue,
      })
    );
    this.showLoadingVesselData = true;
    this.checkImportIMO();
  }

  validateIMO(imoValue) {
    if (!imoValue || imoValue.trim() === "") {
      return {
        isValid: false,
        errorMessage: "IMO number cannot be empty.",
      };
    }

    if (!/^\d+$/.test(imoValue)) {
      return {
        isValid: false,
        errorMessage: "IMO number must contain only numeric characters.",
      };
    }

    if (imoValue.length !== 7) {
      return {
        isValid: false,
        errorMessage: "IMO number must be exactly 7 digits.",
      };
    }
    return { isValid: true, errorMessage: "" };
  }

  checkImportIMO() {
    this.error$
      .pipe(
        filter((error) => {
          return !!error;
        }),
        take(1)
      )
      .subscribe((error) => {
        this.showLoadingVesselData = false;
        if (error === "import vessel failed") {
          this.showIMOerror = true;
          this.IMOErrorMessage =
            "Import failed. Please verify the specified IMO number.";
        } else if (error === "get stats failed") {
          this.showIMOerror = true;
          this.IMOErrorMessage =
            "Vessel not found. Please verify the specified IMO number.";
        }
      });

    this.newVesselData$
      .pipe(
        filter((newVesselData) => {
          return !!newVesselData;
        }),
        take(1)
      )
      .subscribe((result) => {
        this.showLoadingVesselData = false;

        this.imageUrl =
          result.imageUrl != null && result.imageUrl !== ""
            ? result.imageUrl
            : this.baseImageUrl;

        const newValues = {
          IMO: result.imo,
          imageUrl:
            result.imageUrl != null && result.imageUrl !== ""
              ? result.imageUrl
              : "",
          VesselName: result.name,
          VesselType: result.type,
          VesselDWT: result.dwt,
          VesselLength: result.length,
          VesselBeam: result.beam,
        };

        this.vesselBuilderForm.patchValue(newValues);
        this.selectItem(result.type);
      });
  }

  createVessel() {
    let hasError: boolean = false;
    const data = this.vesselBuilderForm.value;

    const imoValue = this.vesselBuilderForm.get("IMO")?.value;
    const validation = this.validateIMO(imoValue);

    if (!validation.isValid) {
      this.showIMOerror = true;
      this.IMOErrorMessage = validation.errorMessage;
      return;
    } else {
      this.showIMOerror = false;
      this.IMOErrorMessage = "";
    }

    for (const [key, value] of Object.entries(data)) {
      if ((value == "" || value == null) && key !== "imageUrl") {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      this.toasterService.error("All fields are required");
    } else {
      this.showLoadingCreate = true;
      this.vesselsOverviewStore.dispatch(
        VesselsOverviewActions.createVessel({ vessel: data })
      );
      this.checkVesselCreated();
    }
  }

  checkVesselCreated() {
    this.error$
      .pipe(
        filter((error) => {
          return !!error;
        }),
        take(1)
      )
      .subscribe((error) => {
        if (error === "create vessel failed") {
          this.toasterService.error("Vessel creation failed.");
          this.showLoadingCreate = false;
        } else {
          this.toasterService.error("An unexpected error occurred.");
          this.showLoadingCreate = false;
        }
      });
    this.createdVessel$
      .pipe(
        filter((createdVessel) => !!createdVessel),
        take(1)
      )
      .subscribe((result) => {
        if (result != null) {
          this.showLoadingCreate = false;
          this.toasterService.success("Vessel created successfully.");
          this.vesselsOverviewStore.dispatch(
            VesselsOverviewActions.getActiveStats({ id: this.companyId })
          );
          this.vesselsOverviewStore.dispatch(
            VesselsOverviewActions.getActiveVessels({ id: this.companyId })
          );
          this.vesselsOverviewStore.dispatch(
            VesselsOverviewActions.getArchivedStats({ id: this.companyId })
          );
          this.vesselsOverviewStore.dispatch(
            VesselsOverviewActions.getArchivedVessels({ id: this.companyId })
          );
          this.toggleDrawer();
        }
      });
  }
}
