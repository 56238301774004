import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CompanyFoldersService } from '../../../../services/company-folders.service';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { FoldersActions } from '../../store/actions/folder.actions';
import { selectArchivedFolders, selectArchiveFolderId, selectCompetedFolders, selectDraftFolders, selectsLastSelectedNode } from '../../store/selectors/folder.selectors';
import { takeUntil } from 'rxjs/operators';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ProjectsActions } from '../../store/actions/project.actions';

@Component({
  selector: 'app-folder-node',
  templateUrl: './folder-node.component.html',
  styleUrls: ['./folder-node.component.scss']
})
export class FolderNodeComponent implements OnInit,OnChanges {
  @Input() flatTree:any;
  @Input() rootFolder:any;
  @Input() canSelectTargetFolder:any;
  lastSelectedNode$:Observable<any>;
  treeData:any=[];
  lastSelectedNode:any=null;
  lookup: Record<any, boolean> = {};
  targetMoveFolder:any=null;
  private destroy$ = new Subject<void>();
  constructor(
    private companyFoldersService:CompanyFoldersService,
    private foldersStore: Store<{folders:FoldersState}>,
    private projectsStore: Store<{ folders: ProjectsState }>,
  ) { 
  }
  
  ngOnInit(): void {
    this.foldersStore.select(this.rootFolder=='Draft'?selectDraftFolders:(this.rootFolder=='Completed'?selectCompetedFolders:selectArchivedFolders))
    .pipe(takeUntil(this.destroy$)) 
    .subscribe(myArray => {
      
    });
    
  }
  ngOnChanges(): void {
    this.lastSelectedNode$=this.foldersStore.select(selectsLastSelectedNode)
    this.treeData=[];
    this.treeData=this.companyFoldersService.buildTree(this.flatTree,this.rootFolder,this.lookup);
    
  }

  toggleNode(node: any) {
    node.expanded = !node.expanded;
    this.lookup[node.id]=node.expanded;
    this.foldersStore.dispatch(ProjectsActions.trackLastProjectsDispatcher({node}));
    this.foldersStore.dispatch(FoldersActions.trackLastNode({lastSelectedNode:{id:null,root:this.rootFolder}}));
    if(node.parentId!=null){
      if(this.lastSelectedNode&&this.lastSelectedNode.id!=node.id){
        this.projectsStore.dispatch(ProjectsActions.clearProjects());
      }
      this.lastSelectedNode=node;
      this.foldersStore.dispatch(FoldersActions.trackLastNode({lastSelectedNode:{id:node.id,root:this.rootFolder}}));
    }
  }

  archive(node){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:true,
          archiveOperation:'archive',
          folderName:node.folderName,
          targetId:node.id,
          targetType:'folder',
          source:this.rootFolder
        }
      }
    ))
  }

  restore(node){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:true,
          archiveOperation:'restore',
          folderName:node.folderName,
          targetId:node.id,
          targetType:'folder',
          source:this.rootFolder
        }
      }
    ))
  }

  addFolder(node){
    this.foldersStore.dispatch(FoldersActions.toggleUpsertFolder(
      {
        upsertFolderProps:{
          modalType:'create-sub-folder',
          oldFolderId:null,
          oldFolderName:null,
          targetParentId:node.id,
          canShowUpsert:true,
          completed:(this.rootFolder=='Completed'?true:false)
        }
      }
    ))
  }

  editFolder(node){
    this.foldersStore.dispatch(FoldersActions.toggleUpsertFolder(
      {
        upsertFolderProps:{
          modalType:'edit-folder',
          oldFolderId:null,
          oldFolderName:node.folderName,
          targetParentId:node.id,
          canShowUpsert:true,
          completed:null,
        }
      }
    ))
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
