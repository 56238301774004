import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ScenariosState } from '../../store/reducers/scenarios.reducers';
import { combineLatest, Observable } from 'rxjs';
import { selectBaselineMetaData, selectResultScopes, selectScenariosData, selectVesselData } from '../../store/selectors/scenarios.selectors';
import { ScenariosActions } from '../../store/actions/scenarios.actions';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { selectCurrentVessel, selectCurrentVesselLifecycleId } from '../../store/selectors/vessels-overview.selectors';
import { loadScenariosFromLocalStorage, loadVesselsFromLocalStorage } from '../../store/utils/scenario-storage.util';
import { VesselsOverviewActions } from '../../store/actions/vessels-overview.actions';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-vessel-lifecycle-screening-result',
  templateUrl: './vessel-lifecycle-screening-result.component.html',
  styleUrls: ['./vessel-lifecycle-screening-result.component.scss']
})
export class VesselLifecycleScreeningResultComponent implements OnInit {
  vessel: any; // Define a vessel property
  activeTab: string = 'active'; // Default active tab
  vesselId$:Observable<any>;
  vesselLifecycleId$:Observable<any>;
  scenariosMetaData$:Observable<any>;
  vesselData$:Observable<any>;
  scenariosData$:Observable<any>;
  resultScopes$:Observable<any>;
  currentVesselId:any=0;
  currentVesselLifecycleId:any=0;
  scenariosMetaData:any=null;
  step2Data:any=null;
  step3Data:any=null;
  vesselData:any=null;
  resultScopes:any=null;
  resultScopesPercentValues:any={
    total:0,
    scope1:0,
    scope2:0,
    scope3:0,
  }

  constructor(
    private scenariosStore: Store<{ scenarios: ScenariosState }>,
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { 
      this.vesselId$=vesselsOverviewStore.select(selectCurrentVessel);
      this.vesselLifecycleId$=vesselsOverviewStore.select(selectCurrentVesselLifecycleId);
      this.scenariosMetaData$=vesselsOverviewStore.select(selectBaselineMetaData);
      this.scenariosData$=vesselsOverviewStore.select(selectScenariosData);
      this.resultScopes$=vesselsOverviewStore.select(selectResultScopes);
      this.vesselData$=scenariosStore.select(selectVesselData);

      const savedScenarios = loadScenariosFromLocalStorage();
      if (savedScenarios) {
        this.scenariosStore.dispatch(ScenariosActions.setScenarios({ scenarios: savedScenarios }));
      }
      const savedVessels = loadVesselsFromLocalStorage();
      if (savedVessels) {
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.setVessels({ vessels: savedVessels }));
      }


      combineLatest([
        this.vesselId$,
        this.vesselLifecycleId$,
        this.scenariosMetaData$
      ]).subscribe(([vId, vlcId,md]) => {
        this.currentVesselId=vId;
        this.currentVesselLifecycleId=vlcId;
        this.scenariosMetaData=md;
        this.scenariosStore.dispatch(ScenariosActions.getVesselData({vesselId:this.currentVesselId}));
        this.scenariosStore.dispatch(ScenariosActions.getScenarioInventoriesForResult({id:this.scenariosMetaData[1].id,step:"step2"}));
        this.scenariosStore.dispatch(ScenariosActions.getScenarioInventoriesForResult({id:this.scenariosMetaData[2].id,step:"step3"}));
        this.scenariosStore.dispatch(ScenariosActions.getVesselData({vesselId:this.currentVesselId}));
        this.scenariosData$.subscribe(ans=>{
          this.step2Data=ans['step2'];
          this.step3Data=ans['step3'];
        })
      });
   
    this.vesselData$.pipe(
      filter(vesselData => !!vesselData), 
      take(1)
    ).subscribe(ans => {
      this.vesselData=ans;
      const vesselTypesmap={
        "Bulk Carrier":"bulkcarrier",
        "Bunkering Tanker":"tanker",
        "Chemical/Oil Products Tanker":"tanker",
        "Crude Oil Tanker":"tanker",
        "Container Ship":"container",
        "Tug":"tugboat",
        "Crane Ship":"",
        "Crew Boat":"",
        "Deck Cargo Ship":"",
        "Dredger":"",
        "FSO":"",
        "General Cargo Ship":"",
        "N/A":"",
        "Offshore Support Vessel":"",
        "Passenger (Cruise) Ship":"",
        "Yacht":"",
      }
      
      this.scenariosStore.dispatch(ScenariosActions.getScope1Impact({ id: this.currentVesselId }));
      this.scenariosStore.dispatch(ScenariosActions.getScope2Impact({ id: this.currentVesselId }));
      this.scenariosStore.dispatch(ScenariosActions.getScope3Impact({ id: this.currentVesselId, vesselType: vesselTypesmap[this.vesselData.vesselType] }));
      this.resultScopes$.subscribe(ans=>{
        if(ans!=null){

          this.resultScopes=ans;
          this.resultScopesPercentValues.total=parseFloat(ans['scope1']?.totalImpact??0)+parseFloat(ans['scope2']?.totalImpact??0)+parseFloat(ans['scope3']?.totalClimateImpact??0);
          this.resultScopesPercentValues.scope1=((parseFloat(ans['scope1']?.totalImpact??0)*100)/this.resultScopesPercentValues.total);
          this.resultScopesPercentValues.scope2=((parseFloat(ans['scope2']?.totalImpact??0)*100)/this.resultScopesPercentValues.total);
          this.resultScopesPercentValues.scope3=((parseFloat(ans['scope3']?.totalClimateImpact??0)*100)/this.resultScopesPercentValues.total);
          
        }
      })
    });

    
      
    
     
  }

  ngOnInit(): void {

  }

}
