import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { VesselsOverviewState } from '../store/reducers/vessels-overview.reducers';
import { selectCurrentVessel } from '../store/selectors/vessels-overview.selectors';
import { VesselsOverviewActions } from '../store/actions/vessels-overview.actions';

@Component({
  selector: 'app-emission-navigator',
  templateUrl: './emission-navigator.component.html',
  styleUrls: ['./emission-navigator.component.scss']
})
export class EmissionNavigatorComponent implements OnInit {
  cardTitle: string = '';
  isModalOpen: boolean = false; 
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { }

  ngOnInit(): void {
  }


  startScreening(): void {
    this.vesselsOverviewStore.select(selectCurrentVessel).subscribe(ans=>{
      this.vesselsOverviewStore.dispatch(VesselsOverviewActions.initiateScenarios({vesselId:ans}))
    })
    //this.router.navigate([vessel.id+'/emission-navigator'], { relativeTo: this.route });
  }


 

  openModal(title: string): void {
    this.cardTitle = title; // Set the card title
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  handleConfirm(sessionName: string): void {
    const fullSessionName = `${this.cardTitle} - ${sessionName}`; // Combine card title and session name
    this.isModalOpen = false;
  
    // Navigate to the next component with the session name
    this.router.navigate(['/danish-shipping/custom-scenario'], { queryParams: { sessionName: fullSessionName } });
  }
  
}
