import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { ToastrService } from 'ngx-toastr';
import { ScenariosState } from '../../store/reducers/scenarios.reducers';
import { ScenariosActions } from '../../store/actions/scenarios.actions';

@Component({
  selector: 'app-bls-step1',
  templateUrl: './bls-step1.component.html',
  styleUrls: ['./bls-step1.component.scss']
})
export class BlsStep1Component implements OnInit {

  @Input() step1data:any=null;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private scenariosStore: Store<{ scenarios: ScenariosState }>,
    private toasterService: ToastrService,
  ) {
    
  }

  ngOnInit(): void {
    
  }

}
